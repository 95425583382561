import React, { useEffect, useRef, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import SettingAndNotifications from "./SettingAndNotifications";
import NavbarMenuPopover from "./NavbarMenuPopover";
import MobileViewPopover from "./NavbarMenuPopover/MobileViewPopover";
import xpentraLogo from "../../assets/TransactionDashboardIcons/Xpentra.png";
import CustomizedBreadcrumbs from "./CustomizedBreadcrumbs";
import "./navbar.css";
import { Divider, Popover } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UserProfile from "../_utils/userProfile/userprofile";
import Profile from "../../assets/HomeDashboard/Profile.png";
import { setCurrentMenu } from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { setCurrentMenum } from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import homeunselect from "../../assets/newdashboardicon/home_unselected.png";
import homeselect from "../../assets/newdashboardicon/home_selected.png";
import transactionunselect from "../../assets/newdashboardicon/transaction_unselected.png";
import transactionselect from "../../assets/newdashboardicon/transaction_selected.png";

import accountunselect from "../../assets/newdashboardicon/account_unselected.png";
import accountselect from "../../assets/newdashboardicon/account_selected.png";

import profileunselect from "../../assets/newdashboardicon/profile_unselected.png";
import profileselect from "../../assets/newdashboardicon/profile_selected.png";


const pages = ["Home", "Transactions", "Accounts"];

const pagesm = ["Home", "Transactions", "Accounts", "Profile"];

const transactions = [
  
  {
    title: "Debit Transactions",
    menuItems: [
      {
        icon: "Beneficiary.png",
        label: "Beneficiary Transactions",
        labelm: "Beneficiary",
        labela: "Transactions",
        route: "beneficiary_transactions",
        menu: "Transactions",
      },
    ],
  },
  
];
const accounts = [
  {
    title: "Corporate accounts",
    menuItems: [
      // {
      //   icon: "InstantPay.png",
      //   label: "InstantPay Accounts",
      //   route: "instantpay_accounts",
      //       menu: 'Accounts'

      // },
      {
        icon: "Internal.png",
        label: "Owner Accounts",
        labelm: "Owner",
        labela: "Accounts",
        route: "accounts/owner/dashboard",
        menu: "Accounts",
      },
      // {
      //   icon: "Vendor.png",
      //   label: "Cards Accounts",
      //   route: "accounts/cards/dashboard",
      //       menu: 'Accounts'
      // },
    ],
  },
  {
    title: "Debit Accounts",
    menuItems: [
      // {
      //   icon: "Vendor.png",
      //   label: "Vendor Accounts",
      //   route: "accounts/vendor/dashboard",
      //       menu: 'Accounts'
      // },
      {
        icon: "Beneficiary.png",
        label: "Beneficiary Accounts",
        labelm: "Beneficiary",
        labela: "Accounts",
        route: "accounts/beneficiary/dashboard",
        menu: "Accounts",
      },
    ],
  },
  // {
  //   title: "Employee Accounts",
  //   menuItems: [
  //     {
  //       icon: "Field force.png",
  //       label: "Field Force Accounts",
  //       route: "accounts/field_force/dashboard",
  //       menu: 'Accounts'
  //     },
  //     {
  //       icon: "Reimbursement.png",
  //       label: "Reimbursement Accounts",
  //       route: "reimbursement_accounts",
  //       menu: 'Accounts'
  //     },
  //   ],
  // },
  // {
  //   title: "Credit Accounts",
  //   menuItems: [
  //     {
  //       icon: "Client.png",
  //       label: "Client Accounts",
  //       route: "accounts/client/dashboard",
  //       menu: 'Accounts'
  //     },
  //   ],
  // },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openMobilePopover, setOpenMobilePopover] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const [arrowUp, setArrowUp] = useState({
    Transactions: false,
    Accounts: false,
    Home: true,
  });
  const [arrowUpm, setArrowUpm] = useState({
    Transactions: false,
    Accounts: false,
    Home: true,
    Profile: false,
  });
  const appBarRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const hideBreadCrumb = useSelector((state) => state.hideBreadCrumb.hide);
  const currentmenu = useSelector((state) => state.hideBreadCrumb.menu);

  const handleClick = () => {
    setZIndex(prevZIndex => prevZIndex === 1 ? 2 : 1); // Toggle z-index between 1 and 10
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (appBarRef.current && !appBarRef.current.contains(event.target)) {
        setOpenPopover(false);
        setOpenMobilePopover(false);
        setArrowUp({
          Transactions: false,
          Accounts: false,
          Home: false,
        });
         setArrowUpm({
          Transactions: false,
          Accounts: false,
          Home: false,
          Profile: false,
        });
      }
      setZIndex(prevZIndex => prevZIndex === 2 ? 1 : 1);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [appBarRef]);

  useEffect(() => {
    if (openProfile) {
      setOpenProfile(false);
    }
    let menu = location.pathname.split("/")[1];
    if (menu == "accounts") {
      dispatch(setCurrentMenu("Accounts"));
    } else if (menu == "beneficiary_transactions" || menu == "transaction") {
      dispatch(setCurrentMenu("Transactions"));
    } else {
      dispatch(setCurrentMenu("Home"));
    }
  }, [location.pathname]);

  useEffect(() => {
    let menu = location.pathname.split("/")[1];
    if (menu == "accounts") {
      dispatch(setCurrentMenum("Accounts"));
    } else if (menu == "beneficiary_transactions" || menu == "transaction") {
      dispatch(setCurrentMenum("Transactions"));
    } else if (menu == "settings") {
      dispatch(setCurrentMenum("Profile"));
    } else {
      dispatch(setCurrentMenum("Home")) || dispatch(setCurrentMenum("Profile"));
    }
  }, [location.pathname]);

  useEffect(() => {
    if (arrowUp.Transactions || arrowUp.Accounts) {
      setOpenPopover(true);
    } else {
      setOpenPopover(false);
    }
  }, [arrowUp]);

  useEffect(() => {
    if (arrowUpm.Transactions || arrowUpm.Accounts) {
      setOpenMobilePopover(true);
    } else {
      setOpenMobilePopover(false);
    }
  }, [arrowUpm]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setOpenPopover(false);
    setOpenMobilePopover(true);
    setAnchorElNav(null);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
    setOpenMobilePopover(false);
    setArrowUp({
      Transactions: false,
      Accounts: false,
      Home: true,
    });
    setArrowUpm({
      Transactions: false,
      Accounts: false,
      Home: true,
      Profile: false,
    });
  };

  const [isMobile, setIsMobile] = useState(false);

  const [zIndex, setZIndex] = useState(1);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 801);
    };

    window.addEventListener('resize', handleResize);

    // Check the screen size when the component mounts
    handleResize();

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  return (
    <>
      {!isMobile && (
      <AppBar
        ref={appBarRef}
        style={{
          background: "white",
          height: "65px",
          borderRadius: hideBreadCrumb ? "0px 0px 100px 100px" : "0px",
        }}
      >
        <Container className="mx-auto" sx={{ maxWidth: "1024px" }}>
          <Toolbar disableGutters>            
            <Typography
              variant="h6"
              marginLeft="5%"
              component="a"
              onClick={() => {
                navigate("/home");
                dispatch(setCurrentMenu("Home"));
              }}
            >
              <img
                className="cursor-pointer"
                width="120px"
                src={xpentraLogo}
                alt="xpentraLogo"
              />
            </Typography>

            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            ></Typography>

            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                margin: "0 20%",
              }}
            >
              {pages.map((page) => (
                <Link key={page} to={page === "Home" ? "/home" : "#"}>
                  <Button
                    onClick={() => {
                      if (page === "Home") {
                        dispatch(setCurrentMenu(page));
                      } else if (page === "Accounts") {
                        setArrowUp({ ["Home"]: false });
                        setArrowUp({
                          ["Transactions"]: false,
                        });
                        setArrowUp({ [page]: !arrowUp[page] });
                      }
                      if (page === "Transactions") {
                        setArrowUp({ ["Home"]: false });
                        setArrowUp({ ["Accounts"]: false });
                        setArrowUp({ [page]: !arrowUp[page] });
                      }
                    }}
                    sx={{
                      display: "flex",
                      fontSize: "18px",
                      margin: "0 2%",
                      fontWeight: page === currentmenu ? 600 : "none",
                      color: page === currentmenu ? "#152E77" : "grey",
                      textTransform: "capitalize",
                    }}
                  >
                    {page}
                    {page === "Transactions" || page === "Accounts" ? (
                      arrowUp[page] ? (
                        <ArrowDropUpIcon
                          sx={{ fontSize: "200%", margin: "-6% 0%" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          sx={{ fontSize: "200%", margin: "-6% 0%" }}
                        />
                      )
                    ) : null}
                  </Button>
                </Link>
              ))}
              {openProfile && (
                <div
                  className="w-full h-full"
                  style={{
                    position: "fixed",
                    top: 0,
                    maxWidth: "1024px !important",
                  }}
                >
                  <Popover
                    PaperProps={{
                      style: {
                        borderRadius: "20px",
                        backgroundColor: "#102C5D",
                        height: "auto",
                        width: "305px",
                        position: "fixed",
                        top: "0 !important",
                        right: "10% !important",
                        left: "auto !important",
                      },
                    }}
                    id={"profile"}
                    open={openProfile}
                    onClose={() => setOpenProfile(false)}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    sx={{
                      "& .MuiPaper-root": {
                        position: "fixed",
                        top: "10px !important",
                        right: "10% !important",
                        left: "auto !important",
                      },
                      position: "fixed",
                      top: "10px !important",
                      right: "10% !important",
                      left: "auto !important",
                    }}
                  >
                    <UserProfile
                      setOpenProfile={setOpenProfile}
                      openProfile={openProfile}
                    />
                  </Popover>
                </div>
              )}
            </Box>
            <div
              className="SettingAndNotifications hover:cursor-pointer"
              aria-describedby={"profile"}
              onClick={() => setOpenProfile(!openProfile)}
            >
              <img
                src={Profile}
                alt="Profile"
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            </div>
            {/* {services && (
                <Box
                  className="SettingAndNotifications"
                  onClick={handleClosePopover}
                >
                  <SettingAndNotifications />
                </Box>
              )} */}
          </Toolbar>
          <Divider style={{ maxWidth: "1024px", margin: "0 auto" }} />
        </Container>
        {!hideBreadCrumb ? (
          <Box className="desktopPopover hidden md:block">
            {openPopover ? (
              <NavbarMenuPopover
                open={openPopover}
                setOpenPopover={setOpenPopover}
                setArrowUp={setArrowUp}
                data={arrowUp["Transactions"] ? transactions : accounts}
              />
            ) : (
              <Box
                sx={{
                  backgroundColor: "white",
                  height: "40px",
                  width: "100%",
                  padding: 0,
                  margin: 0,
                  borderRadius: "0 0px 100px 100px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  position: "static",
                }}
              >
                <div className="w-full px-3">
                  <CustomizedBreadcrumbs />
                </div>
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            {openPopover ? (
              <NavbarMenuPopover
                open={openPopover}
                setOpenPopover={setOpenPopover}
                setArrowUp={setArrowUp}
                data={arrowUp["Transactions"] ? transactions : accounts}
              />
            ) : null}
          </Box>
        )}
      </AppBar>
      )}
      {isMobile && (
        <>
          <AppBar
            ref={appBarRef}
            style={{
              background: "#506994",
              height: "100px",
              borderRadius: "40px 40px 0px 0px",
              top: 'auto',
              bottom: '0px',
              zIndex: zIndex
            }}
          >
            <Container className="mobilmenumain">
              {pagesm.map((pagem) => (
                <Link key={pagem} to={pagem === "Home" ? "/home" : "#"} className="mobilmenu">
                  <div
                    onClick={() => {
                      if (pagem === "Home") {
                        dispatch(setCurrentMenum(pagem));
                      } else if (pagem === "Accounts") {
                        setArrowUpm({ ["Home"]: false });
                        setArrowUpm({ ["Transactions"]: false, });
                        setArrowUpm({ ["Profile"]: false, });
                        setArrowUpm({ [pagem]: !arrowUp[pagem] });
                        handleClick()
                      }
                      if (pagem === "Transactions") {
                        setArrowUpm({ ["Home"]: false });
                        setArrowUpm({ ["Accounts"]: false });
                        setArrowUpm({ ["Profile"]: false, });
                        setArrowUpm({ [pagem]: !arrowUpm[pagem] });
                        handleClick()
                      }

                      if (pagem === "Profile") {
                        dispatch(setCurrentMenum(pagem));
                        navigate("/settings/Profile");
                      }
                    }}                    
                  >
                   
                    {pagem === "Home" ? (
                       pagem === currentmenu ? <img
                          width={"30%"}
                          src={homeselect}
                          alt=""
                          style={{ margin: "5px auto" }}
                        /> : <img
                          width={"30%"}
                          src={homeunselect}
                          alt=""
                          style={{ margin: "5px auto" }}
                        />
                    
                          
                    ) : null}
                    {pagem === "Transactions" ? (
                         pagem === currentmenu ? <img
                          width={"30%"}
                          src={transactionselect}
                          alt=""
                          style={{margin:"5px auto"}}
                        /> : <img
                          width={"30%"}
                          src={transactionunselect}
                          alt=""
                          style={{ margin: "5px auto" }}
                        />
                    ) : null}
                    {pagem === "Accounts" ? (
                         pagem === currentmenu ? <img
                          width={"30%"}
                          src={accountselect}
                          alt=""
                          style={{margin:"5px auto"}}
                        /> : <img
                          width={"30%"}
                          src={accountunselect}
                          alt=""
                          style={{ margin: "5px auto" }}
                        />
                    ) : null}
                    {pagem === "Profile" ? (
                         pagem === currentmenu ? <img
                          width={"30%"}
                          src={profileselect}
                          alt=""
                          style={{margin:"5px auto"}}
                        /> : <img
                          width={"30%"}
                          src={profileunselect}
                          alt=""
                          style={{ margin: "5px auto" }}
                        />
                    ) : null}
                    {pagem}
                    {pagem === "Transactions" || pagem === "Accounts" ? (
                        arrowUpm[pagem] ? (
                          <></>
                        ) : (
                          <></>
                        )
                      ) : null}
                  </div>
                </Link>
              ))}
            </Container>             
            <Box className="mobilePopover">
              {openMobilePopover ? (
                <MobileViewPopover
                  open={openMobilePopover}
                  setOpenMobilePopover={setOpenMobilePopover}
                  setArrowUpm={setArrowUpm}
                  data={arrowUpm["Transactions"] ? transactions : accounts}
                />
              ) : null}
            </Box>
          </AppBar>
        </>
      )}
    </>
  );
}
export default ResponsiveAppBar;
