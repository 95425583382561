import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  beneVerifyThisGST,
  beneWaitingApproved,
} from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import "./index.css";
import arrowRight from "../../../../assets/Accounts/Create/arrowright.png";
import skipping_verification from "../../../../assets/CreateTransactionIcons/Skipping verivifaction.png";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import { Box, Tooltip, tooltipClasses } from "@mui/material";
import editImage from "../../../../assets/Beneficiary Icons/new_edit.png";
import declineImage from "../../../../assets/Beneficiary Icons/reddecline.png";
import associated_accounts_found from "../../../../assets/Beneficiary Icons/associated_accounts_found.png";
import checkmark from "../../../../assets/Beneficiary Icons/checkmark.png";
import archieveImage from "../../../../assets/Beneficiary Icons/new_archive.png";
import transactionHistory from "../../../../assets/TxnDetails/new_transaction_history.png";
import payBene from "../../../../assets/Beneficiary Icons/paybene.png";
import { viewAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";
import unarchiveImage from "../../../../assets/Beneficiary Icons/unarchive.png";
import BeneEditModal from "../../../../components/BeneEditAccount/BeneEditModal";
import formatDateViewAll from "../../../../utils/formatDateViewAll";
import OwnerTransactionHistoryModal from "../../../../components/Accounts/OwnerAccounts/OwnerTransactionHistoryModal";
import BeneIcon from "../../../../assets/CreateTransactionIcons/Beneficiary.png";
import DeclinedPopup from "../BeneAcountApproval/DeclinedPopup";
import BeneDetailModal from "../../../../components/Accounts/BeneDetailModal";
import dataLoading from "../../../../assets/Beneficiary Icons/inputloder.gif";
import { beneLookUp } from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneBankLookUp } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { toast } from "react-toastify";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import BeneViewAssociatedAccount from "../../../../components/BeneViewAssociatedAccount";
import { checkUsertoken } from "../../../../redux/features/login/login";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import RejectingTransactionPopup from "../BeneAcountApproval/RejectingTransactionPopup/RejectingTransactionPopup";

const BeneAccDetails = (payload) => {
  const navigate = useNavigate();
  const [showCategories, setShowCategories] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(true);
  const [selectedId, setSelectedId] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [unachivedOpenPop, setUnachivedOpenPop] = useState(false);
  const [achivedOpenPop, setAchivedOpenPop] = useState(false);
  const [ViewAccountDetails, SetViewAccountDetails] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChecker, setIsChecker] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [deniedId, SetdeniedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openTransHistory, setOpenTransHistory] = useState(false);
  const [openAssociatedAccounts, setOpenAssociatedAccounts] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const [nameArchive, setNameArchive] = useState("");
  const [currentAccountDetails, setCurrentAccountDetails] = useState({
    name: "",
    bank_name: "",
    ifsc_code: "",
    bank_acc_no: "",
    category: "",
    id: "",
    pan: "",
  });
  const [showRejectTnxPopup, setRejectTxnPopup] = useState(false);
  const [showRejectAllTxnReson, setShowRejectAllTxnReson] = useState(false);
  const services = useSelector((state) => state.refreshToken.services);
  const handViewAccount = () => {
    setUnachivedOpenPop(false);
  };
  const handleModalClose = () => {
    setIsEditModalOpen(false);
  };
  const { id } = useParams();
  const dispatch = useDispatch();

  const { viewAccountDetailData, viewAccountDetailError } = useSelector(
    (state) => state.verify
  );

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        dispatch(checkUsertoken());
        let response = await dispatch(viewAccountDetail({ id }));
        if (response?.error?.message == "Request failed with status code 404") {
          navigate("*");
        }
      } catch (error) {
        console.error("Failed to load the account details", error);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [id, dispatch]);

  useEffect(() => {
    if (viewAccountDetailData) {
      SetViewAccountDetails(viewAccountDetailData?.beneficiary_accounts);
    }
  }, [viewAccountDetailData]);

  useEffect(() => {
    let val =
      services?.find(
        (service) =>
          service === "BAAccount" ||
          service === "BUAccount" ||
          service === "Admin"
      ) === undefined;
    setIsChecker(val);
  }, [dispatch, services]);

  useEffect(() => {
    if (
      viewAccountDetailData &&
      viewAccountDetailData.beneficiary_accounts &&
      viewAccountDetailData.beneficiary_accounts.length > 0
    ) {
      const {
        pan_legal_name,
        bank_name,
        bank_acc_no,
        category,
        ifsc_code,
        id,
        pan_no,
      } = viewAccountDetailData.beneficiary_accounts[0];
      setCurrentAccountDetails({
        name: pan_legal_name,
        bank_name,
        bank_acc_no,
        category,
        ifsc_code,
        id,
        pan: pan_no,
      });
    }
  }, [viewAccountDetailData]);

  const handleModalOpen = (id) => {
    setIsEditModalOpen(id);
    setSelectedId(id);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setUnachivedOpenPop(false);
    setAchivedOpenPop(false);
  };

  const handleUnarchive = () => {
    setUnachivedOpenPop(true);
    if (viewAccountDetailData?.beneficiary_accounts[0]?.archived === "N") {
      setUnachivedOpenPop(false);
    } else {
      setUnachivedOpenPop(true);
    }
  };
  const handleArchive = () => {
    if (viewAccountDetailData?.beneficiary_accounts[0]?.archived === "N") {
      setAchivedOpenPop(true);
    } else {
      setAchivedOpenPop(false);
    }
  };

  const toggleCategories = () => {
    setShowCategories(true);
    setShowContactDetails(false);
  };
  const toggleContactDetails = () => {
    setShowContactDetails(true);
    setShowCategories(false);
  };

  const openTransactionHistory = () => {
    setOpenTransHistory(true);
  };

  const closeTransactionHistory = () => {
    setOpenTransHistory(false);
  };

  const openAssociatedAccountsFound = () => {
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: currentAccountDetails.pan }));
    dispatch(
      beneBankLookUp({
        ifsc: currentAccountDetails.ifsc_code,
        bank_account_no: currentAccountDetails.bank_acc_no,
      })
    );
    setOpenAssociatedAccounts(true);
  };

  const closeAssociatedAccountsFound = () => {
    setOpenAssociatedAccounts(false);
  };

  const createTransaction = () => {
    if (
      services?.find(
        (service) => service === "BUAccount" || service === "Admin"
      ) !== undefined &&
      currentAccountDetails.category.length > 0
    ) {
      navigate("/transaction/beneficiary_transaction/create", {
        state: currentAccountDetails,
      });
    } else if (
      services?.find(
        (service) => service === "BUAccount" || service === "Admin"
      ) !== undefined &&
      currentAccountDetails.category.length <= 0
    ) {
      toast.error("Category can't be blank", toast_position);
    }
  };

  const handleGSTDetails = async (gstin) => {
    if (gstin?.length === 15) {
      dispatch(checkUsertoken());
      const response = await dispatch(
        beneVerifyThisGST({ search_string: gstin })
      );
      if (response?.payload?.status === true) {
        navigate(`/accounts/beneficiary_account/details/GST_details/${gstin}`);
      } else if (response?.payload?.status === false) {
        toast.error(`${response?.payload?.errors?.base[0]}`, toast_position);
      }
    }
  };

  const handleApprovalDetails = async (id) => {
    dispatch(checkUsertoken());
    const response = await dispatch(beneWaitingApproved(id));
    if (
      response?.payload?.status === false ||
      response?.payload === undefined
    ) {
      let error_msg = "";
      if (response?.payload === undefined) {
        error_msg = "An error occured while taking action on this account";
      } else if (response?.payload?.errors?.beneficiary_account?.[0]) {
        error_msg = response.payload.errors.beneficiary_account[0];
      } else if (response?.payload?.errors?.bank_account_number?.[0]) {
        error_msg = response.payload.errors.bank_account_number[0];
      } else {
        error_msg = "An unknown error occurred.";
      }
      if (error_msg === "Beneficiary account already approved") {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "approved" : "pending",
              };
            }
            return item;
          }
        );
        SetViewAccountDetails(updatedData);
        dispatch(checkUsertoken());
        await dispatch(viewAccountDetail({ id }));
      } else {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "pending" : "",
                error_msg,
              };
            }
            return item;
          }
        );

        SetViewAccountDetails(updatedData);
        dispatch(checkUsertoken());
      }
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    } else {
      const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
        (item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                item.approval_status === "pending" ? "approved" : "pending",
            };
          }
          return item;
        }
      );
      SetViewAccountDetails(updatedData);
      dispatch(checkUsertoken());
      await dispatch(viewAccountDetail({ id }));

      toast.success(" Beneficiary Account approved successfully ", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleDeclinedDetials = (id) => {
    setShowPopup(true);
    SetdeniedId(id);
  };
  const handleClosePopup = () => {
    setRejectTxnPopup(false);
    setShowPopup(false);
    setShowRejectAllTxnReson(false);
  };
  const updateAllAccounts = (updatedAccounts) => {
    SetViewAccountDetails(updatedAccounts);
  };

  const handleAccountSelection = (
    accountName,
    accountNumber,
    ifsc,
    bankLogo,
    category,
    panNo,
    beneAccountId
  ) => {
    setAccountId(beneAccountId);
  };

  useEffect(() => {
    let accountData = [];
    if (viewAccountDetailData) {
      accountData = viewAccountDetailData?.beneficiary_accounts[0].logs;
    }
    if (
      accountData &&
      viewAccountDetailData?.beneficiary_accounts[0].archived === "Y"
    ) {
      setNameArchive(
        [...accountData]
          .filter((fil) => {
            if (fil.action == "Archived") {
              return fil;
            }
          })
          .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))[0]
          .user_full_name
      );
    }
  }, [ViewAccountDetails]);

  const { beneLookData, beneLookLoading, verifyPANisLoading } = useSelector(
    (state) => state.verify
  );

  const bankData = useSelector(
    (state) => state.beneAllVerification.beneLookData
  );

  useEffect(() => {
    if (beneLookData || bankData) {
      if (beneLookData?.total_count > 1 || bankData?.total_count > 1) {
        setIsDisabled(true);
      }
    }
  }, [beneLookData, bankData]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(beneLookUp({ pan_number: currentAccountDetails.pan }));
    dispatch(
      beneBankLookUp({
        ifsc: currentAccountDetails.ifsc_code,
        bank_account_no: currentAccountDetails.bank_acc_no,
      })
    );
  }, [
    currentAccountDetails.pan,
    currentAccountDetails.ifsc_code,
    currentAccountDetails.bank_acc_no,
  ]);

  useEffect(() => {
    sessionStorage.removeItem("PAN GST Edit NewValue");
  }, []);

  return (
    <div className="main-div flex flex-col h-screen overflow-auto bg-[#3a5686]">
      {showRejectTnxPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <RejectingTransactionPopup
            type={"ArchiveAccount"}
            accountId={id}
            handleClosePopup={handleClosePopup}
            setShowRejectAllTxnReson={setShowRejectAllTxnReson}
            setRejectTxnPopup={setRejectTxnPopup}
          />
        </div>
      )}
      {loading ? (
        <div className="loader-container m-auto justify-center items-center flex">
          <img src={dataLoading} alt="" />
        </div>
      ) : (
        <>
          <div className="h-auto bg-[#3a5686] mt-[76px]">
            {showPopup && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <DeclinedPopup
                  type="decline account details"
                  onClose={handleClosePopup}
                  accountId={deniedId}
                  id={id}
                  allAccounts={ViewAccountDetails}
                  updateAllAccounts={updateAllAccounts}
                  showRejectTnxPopup={showRejectTnxPopup}
                  setRejectTxnPopup={setRejectTxnPopup}
                />
              </div>
            )}

            {showRejectAllTxnReson && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneDetailModal
                  type="Reject-All-Transaction"
                  onClose={handleClosePopup}
                  accountId={deniedId}
                  id={id}
                  allAccounts={ViewAccountDetails}
                  updateAllAccounts={updateAllAccounts}
                  setRejectTxnPopup={setRejectTxnPopup}
                />
              </div>
            )}

            {showRejectAllTxnReson && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneDetailModal
                  type="Reject-All-Transaction"
                  onClose={handleClosePopup}
                  accountId={deniedId}
                  id={id}
                  allAccounts={ViewAccountDetails}
                  updateAllAccounts={updateAllAccounts}
                  setRejectTxnPopup={setRejectTxnPopup}
                />
              </div>
            )}

            {openAssociatedAccounts && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneViewAssociatedAccount
                  onAsscociateClose={closeAssociatedAccountsFound}
                  IFSC={currentAccountDetails.ifsc_code}
                  accountNumber={currentAccountDetails.bank_acc_no}
                  beneAccountId={currentAccountDetails.id}
                  panNumber={currentAccountDetails.pan}
                  onAccountSelect={handleAccountSelection}
                  accountName={currentAccountDetails.name}
                  toggleContactDetails={toggleContactDetails}
                />
              </div>
            )}

            {unachivedOpenPop && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneDetailModal
                  type="unarchived"
                  id={id}
                  title={"Unarchive account"}
                  message={"Are you sure you want to unarchive this account?"}
                  note={"This account will become active."}
                  close={handleCloseModal}
                  createFlow={"viewAll"}
                  payload={payload}
                />
              </div>
            )}
            {achivedOpenPop && (
              <div className="fixed top-0 left-0 w-full h-full bg-[#000] bg-opacity-80 flex items-center justify-center z-[9998]">
                <BeneDetailModal
                  type="archived"
                  id={id}
                  title={"Archive account"}
                  message={"Are you sure you want to archive this account?"}
                  note={"This account will become inactive."}
                  close={handleCloseModal}
                  createFlow={"viewAll"}
                  payload={payload}
                  setRejectTxnPopup={setRejectTxnPopup}
                  setAchivedOpenPop={setAchivedOpenPop}
                />
              </div>
            )}
            {openTransHistory && (
              <div className="fixed top-0 left-0 w-screen h-screen z-[9998] bg-[#000] bg-opacity-80">
                <OwnerTransactionHistoryModal
                  bene_account_id={
                    viewAccountDetailData?.beneficiary_accounts[0]?.id
                  }
                  onClose={closeTransactionHistory}
                />
              </div>
            )}
            <div
              className="w-full max-w-5xl m-auto bg-[#3a5686]"
              id="container"
            >
              <div className="flex max-w-5xl bg-[#3a5686] m-auto h-[30%]">
                <div className="bene-account-primary-details">
                  {viewAccountDetailData?.beneficiary_accounts.map(
                    (account) => (
                      <div
                        key={account.id}
                        className="flex mt-[7.5px] border-b-2 border-solid border-[#1d3a6d]"
                      >
                        <div className="py-5 flex flex-col w-1/2">
                          <div className="bene-account-name text-base text-[#FFFFFF] text-left font-sans font-normal truncate opacity-100">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={account.pan_legal_name.trim()}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  display: "block",
                                }}
                              >
                                {account.pan_legal_name.trim()}
                              </div>
                            </Tooltip>
                          </div>
                          <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                            Account name
                          </p>
                        </div>

                        {ViewAccountDetails?.map((account) => (
                          <div key={account.id} className="w-1/2 p-5">
                            <div className="flex flex-col items-start pl-5 border-l-2 border-solid border-[#1d3a6d]">
                              <div
                                className={`text-base text-left font-semibold capitalize opacity-100 ${
                                  account.approval_status === "pending"
                                    ? "bene-account-pending-status"
                                    : account.approval_status === "approved"
                                    ? "bene-account-approved-status"
                                    : account.approval_status === "rejected"
                                    ? "bene-account-rejected-status"
                                    : account.approval_status === "blocked"
                                    ? "bene-account-blocked-status"
                                    : account.approval_status === "archived"
                                    ? "bene-account-archived-status"
                                    : ""
                                }`}
                              >
                                {account.approval_status}
                              </div>
                              <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                                Account status
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    )
                  )}
                  {viewAccountDetailData?.beneficiary_accounts.map(
                    (account) => (
                      <div
                        key={account.id}
                        className="bene-account-holder-details flex border-b-2 border-solid border-[#1d3a6d]"
                      >
                        <div className="py-5 flex flex-col w-1/2">
                          <p className="text-base text-[#FAFAFA] text-left font-sans font-normal opacity-100">
                            {formatAccountNumber(account.bank_acc_no)}
                          </p>
                          <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                            Account number
                          </p>
                        </div>
                        <div className="p-5 w-1/2">
                          <div className="flex flex-col items-start pl-5 border-l-2 border-solid border-[#1d3a6d]">
                            <div className="bene-account-holder-name w-full text-base text-[#FAFAFA] text-left font-sans font-normal truncate opacity-100">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={account.bank_account_holder_full_name}
                                arrow
                                placement="bottom"
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <div
                                  style={{
                                    width: "100%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "block",
                                  }}
                                >
                                  {account.bank_account_holder_full_name}
                                </div>
                              </Tooltip>
                            </div>
                            <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                              Bank account holder name
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>

                <div className="minorAcc-div">
                  <div className="associated-account-transaction-history-block flex flex-row bg-[#506994] border-b-2 border-solid border-[#1d3a6d]">
                    <div className="w-1/2 flex-grow">
                      <Box
                        className={`${
                          !isDisabled
                            ? "bg-[#506994] text-center h-full flex flex-col justify-center gap-1 p-2 opacity-50 "
                            : "cursor-pointer bg-[#506994] hover:bg-[#667CA3] text-center h-full flex flex-col justify-center gap-1 p-2 "
                        }`}
                        onClick={() => {
                          if (isDisabled) {
                            openAssociatedAccountsFound();
                          }
                        }}
                      >
                        <div className="flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={associated_accounts_found}
                              alt="Associated Accounts"
                              className="w-9 h-9"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                            Associated accounts found
                          </Box>
                        </div>
                      </Box>
                    </div>

                    <div className="py-4 flex">
                      <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                    </div>

                    <div className="w-1/2 flex-grow">
                      <Box
                        className="cursor-pointer bg-[#506994] hover:bg-[#667CA3] text-center h-full flex flex-col justify-center gap-1 p-2"
                        onClick={openTransactionHistory}
                      >
                        <div className="flex justify-center">
                          <Box className="flex justify-center w-10">
                            <img
                              src={transactionHistory}
                              alt="Transaction History"
                              className="w-9 h-9"
                            />
                          </Box>
                        </div>
                        <div className="flex justify-center px-2">
                          <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                            Transaction history
                          </Box>
                        </div>
                      </Box>
                    </div>
                  </div>
                  <div className="settings flex flex-row bg-[#506994] border-b-2 border-solid border-[#1d3a6d]">
                    {services?.find(
                      (service) =>
                        service === "BUAccount" || service === "Admin"
                    ) === undefined ||
                    viewAccountDetailData?.beneficiary_accounts[0]
                      .approval_status === "archived" ||
                    viewAccountDetailData?.beneficiary_accounts[0]
                      .approval_status === "blocked" ? (
                      <>
                        <div className="flex-grow w-1/2">
                          <Box className="opacity-50 text-center h-full flex flex-col justify-center gap-1 p-2">
                            <div className="flex justify-center">
                              <Box className="flex justify-center w-10">
                                <img
                                  src={editImage}
                                  alt="document"
                                  className="edit-img w-9 h-9"
                                />
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                Edit
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          className="flex-grow w-1/2"
                          onClick={() => handleModalOpen(id)}
                        >
                          <Box className="cursor-pointer bg-[#506994] hover:bg-[#667CA3] opacity-100 text-center h-full flex flex-col justify-center gap-1 p-2">
                            <div className="flex justify-center">
                              <Box className="flex justify-center w-10">
                                <img
                                  src={editImage}
                                  alt="document"
                                  className="edit-img w-9 h-9"
                                />
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                Edit
                              </Box>
                            </div>
                          </Box>
                        </div>
                        {isEditModalOpen && (
                          <BeneEditModal onClose={handleModalClose} />
                        )}
                      </>
                    )}
                    <div className="py-4 flex">
                      <div className="flex-grow border border-solid border-[#1d3a6d]"></div>
                    </div>

                    {viewAccountDetailData?.beneficiary_accounts[0]
                      .approval_status === "blocked" ||
                    services?.find(
                      (service) =>
                        service === "BAAccountArchive" || service === "Admin"
                    ) === undefined ? (
                      <>
                        <div className="flex-grow w-1/2">
                          <Box className="opacity-50 text-center h-full flex flex-col justify-center gap-1 p-2">
                            <div className="flex justify-center">
                              <Box className="flex justify-center w-10">
                                {services?.find(
                                  (service) =>
                                    service === "BAAccountArchive" ||
                                    service === "Admin"
                                ) === undefined &&
                                viewAccountDetailData?.beneficiary_accounts[0]
                                  .approval_status === "archived" ? (
                                  <img
                                    src={unarchiveImage}
                                    alt="document"
                                    className="w-9 h-9"
                                  />
                                ) : (
                                  <img
                                    src={archieveImage}
                                    alt="document"
                                    className="w-9 h-9"
                                  />
                                )}
                              </Box>
                            </div>
                            <div className="flex justify-center px-2">
                              <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                {services?.find(
                                  (service) =>
                                    service === "BAAccountArchive" ||
                                    service === "Admin"
                                ) == undefined &&
                                viewAccountDetailData?.beneficiary_accounts[0]
                                  .approval_status === "archived"
                                  ? "Unarchive"
                                  : "Archive"}
                              </Box>
                            </div>
                          </Box>
                        </div>
                      </>
                    ) : (
                      <div
                        className="flex-grow w-1/2 bg-[#506994] hover:bg-[#667CA3]"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BAAccountArchive" ||
                                service === "Admin"
                            ) !== undefined
                          ) {
                            handleUnarchive();
                            handleArchive();
                          }
                        }}
                      >
                        <Box
                          className={`${
                            services?.find(
                              (service) =>
                                service === "BAAccountArchive" ||
                                service === "Admin"
                            ) !== undefined
                              ? "cursor-pointer"
                              : ""
                          } text-center h-full flex flex-col justify-center gap-1  p-2`}
                        >
                          {viewAccountDetailData?.beneficiary_accounts[0]
                            .approval_status === "archived" ? (
                            <>
                              <div
                                className={`${
                                  services?.find(
                                    (service) =>
                                      service === "BAAccountArchive" ||
                                      service === "Admin"
                                  ) !== undefined
                                    ? "opacity-100"
                                    : "opacity-50"
                                } flex justify-center`}
                              >
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={unarchiveImage}
                                    alt="document"
                                    className="w-9 h-9"
                                  />
                                </Box>
                              </div>
                              <div
                                className={`${
                                  services?.find(
                                    (service) =>
                                      service === "BAAccountArchive" ||
                                      service === "Admin"
                                  ) !== undefined
                                    ? "opacity-100"
                                    : "opacity-50"
                                } flex justify-center px-2`}
                              >
                                <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                  Unarchive
                                </Box>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="flex justify-center">
                                <Box className="flex justify-center w-10">
                                  <img
                                    src={archieveImage}
                                    alt="document"
                                    className="w-9 h-9"
                                  />
                                </Box>
                              </div>
                              <div className="flex justify-center px-2">
                                <Box className="text-[10px] text-[#FFFFFF] font-sans font-normal self-center antialiased truncate...">
                                  Archive
                                </Box>
                              </div>
                            </>
                          )}
                        </Box>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center items-center">
            <div className="flex flex-row h-[90px] w-[1024px] mx-auto">
              {viewAccountDetailData?.beneficiary_accounts.map((account) => (
                <>
                  <div key={account.id} className="w-2/5">
                    <div className="flex items-center p-2.5 h-[90px] w-28 bg-[#FAFAFA]">
                      <img
                        src={require("../../../../assets/IfscLogo/" +
                          IfscCodeMapper(account?.ifsc_code))}
                        alt="bank"
                      />
                    </div>
                  </div>

                  <div className="w-3/5 flex flex-row">
                    <div
                      className={`py-6 pl-2.5 ${
                        ViewAccountDetails[0]?.approval_status === "rejected" ||
                        (ViewAccountDetails[0]?.approval_status === "pending" &&
                          ViewAccountDetails[0]?.error_msg?.length > 0)
                          ? "denied-ifsc-div"
                          : "ifsc-div-one"
                      }`}
                    >
                      <div className="flex flex-col items-start pl-5 border-l-2 border-solid border-[#1d3a6d]">
                        <p className="text-base text-[#FAFAFA] text-left font-sans font-normal opacity-100">
                          {account.ifsc_code}
                        </p>
                        <p className="text-xs text-[#DADADA] text-left font-sans font-normal opacity-100">
                          IFSC code
                        </p>
                      </div>
                    </div>
                    <div
                      className={`${
                        (ViewAccountDetails[0]?.approval_status ===
                          "rejected" &&
                          ViewAccountDetails[0]?.error_msg?.length > 0) ||
                        (ViewAccountDetails[0]?.approval_status === "pending" &&
                          ViewAccountDetails[0]?.error_msg?.length > 0)
                          ? "denied-btn-div"
                          : "button-div-element"
                      }`}
                    >
                      <div
                        className={`${
                          services?.find(
                            (service) =>
                              service === "CBeneficiaryTxn" ||
                              service === "Admin"
                          ) === undefined &&
                          viewAccountDetailData?.beneficiary_accounts[0]
                            .approval_status === "approved"
                            ? "opacity-50"
                            : ""
                        } pay-bene`}
                      >
                        {ViewAccountDetails?.map((account) => (
                          <div
                            key={account.id}
                            className={`pay-bene-box2 flex justify-center ${
                              account?.approval_status === "approved" &&
                              services?.find(
                                (service) =>
                                  service === "CBeneficiaryTxn" ||
                                  service === "Admin"
                              ) !== undefined &&
                              currentAccountDetails.category.length > 0
                                ? "approved-pay-bene hover:opacity-75"
                                : account?.approval_status === "approved"
                                ? "approved-pay-bene"
                                : ""
                            }`}
                            onClick={() => {
                              if (
                                services?.find(
                                  (service) =>
                                    service === "CBeneficiaryTxn" ||
                                    service === "Admin"
                                ) !== undefined &&
                                account?.approval_status === "approved"
                              ) {
                                createTransaction();
                              }
                            }}
                          >
                            {account?.approval_status === "approved" ? (
                              <Box
                                className={`${
                                  services?.find(
                                    (service) =>
                                      service === "CBeneficiaryTxn" ||
                                      service === "Admin"
                                  ) !== undefined &&
                                  currentAccountDetails.category.length > 0
                                    ? "cursor-pointer"
                                    : ""
                                } text-center h-full flex flex-col justify-center gap-1 p-2`}
                              >
                                <div className="flex justify-center">
                                  <Box className="self-center w-10">
                                    <img
                                      src={payBene}
                                      alt="document"
                                      className="w-9 h-9"
                                    />
                                  </Box>
                                </div>
                                <div className="flex justify-center">
                                  <Box className="text-xs text-[#F9F9F9] font-sans font-normal self-center antialiased truncate...">
                                    Pay Beneficiary
                                  </Box>
                                </div>
                              </Box>
                            ) : account?.approval_status === "rejected" &&
                              account?.error_msg?.length > 0 ? (
                              <>
                                <div className="flex flex-row gap-x-3 h-full w-full bg-[#BB5353] p-2.5">
                                  <div className="flex place-items-center w-8">
                                    <img src={skipping_verification} />
                                  </div>
                                  <div className="flex flex-col justify-center gap-y-1 w-4/5">
                                    <span className="font-semibold font-sans text-xs text-[#FFFFFF]">
                                      Failed to take action
                                    </span>
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={account?.error_msg}
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <span className="font-sans text-[10px] text-[#FAFAFA] text-ellipsis overflow-hidden w-full block">
                                        {account?.error_msg}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                              </>
                            ) : account.approval_status === "pending" &&
                              account.error_msg?.length > 0 ? (
                              <>
                                <div className="flex flex-row h-full w-full bg-[#BB5353] p-2.5">
                                  <div className="flex place-items-center w-1/6">
                                    <img
                                      src={skipping_verification}
                                      className="w-7 h-7 ml-2"
                                    />
                                  </div>
                                  <div className="flex flex-col justify-center space-y-1 w-5/6">
                                    <span className="font-semibold font-sans text-sm text-[#FFFFFF]">
                                      Failed to take action
                                    </span>

                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={account?.error_msg}
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <span className="font-sans text-xs text-[#FAFAFA] text-ellipsis overflow-hidden w-full block">
                                        {account?.error_msg}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </div>
                              </>
                            ) : account.approval_status === "pending" ? (
                              <>
                                <div
                                  className={`${
                                    services?.find(
                                      (service) =>
                                        service === "BAAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                      ? "opacity-100 cursor-pointer hover:opacity-75"
                                      : "opacity-50"
                                  } w-1/2 flex justify-center	items-center bg-[#ea545b]`}
                                  onClick={
                                    services?.find(
                                      (service) =>
                                        service === "BAAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                      ? () => handleDeclinedDetials(account.id)
                                      : null
                                  }
                                >
                                  <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                                    <div className="decline-image-div flex justify-center">
                                      <Box className="flex justify-center w-10">
                                        <img
                                          src={declineImage}
                                          alt="document"
                                          className="w-9 h-9"
                                        />
                                      </Box>
                                    </div>
                                    <div className="flex justify-center px-2">
                                      <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                        Reject
                                      </Box>
                                    </div>
                                  </Box>
                                </div>
                                <div
                                  className={`${
                                    services?.find(
                                      (service) =>
                                        service === "BAAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                      ? "opacity-100 cursor-pointer hover:opacity-75"
                                      : "opacity-50"
                                  } w-1/2 flex justify-center items-center bg-[#69b76f]`}
                                  onClick={
                                    services?.find(
                                      (service) =>
                                        service === "BAAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                      ? () => handleApprovalDetails(account.id)
                                      : null
                                  }
                                >
                                  <Box className="text-center h-full flex flex-col justify-center gap-1 p-2">
                                    <div className="approve-image-div flex justify-center">
                                      <Box className="flex justify-center w-10">
                                        <img
                                          src={checkmark}
                                          alt="document"
                                          className="w-9 h-9"
                                        />
                                      </Box>
                                    </div>
                                    <div className="flex justify-center px-2">
                                      <Box className="text-[10px] text-[#ffffff] self-center antialiased truncate...">
                                        Approve
                                      </Box>
                                    </div>
                                  </Box>
                                </div>
                              </>
                            ) : null}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>

          <div className="bg-[#1d3a6d] flex-grow w-full">
            <div className="h-auto max-w-5xl bg-[#1d3a6d] m-auto">
              <div className="detail-category-button">
                <button
                  className={`detail-button mr-5 text-xs text-center font-sans font-semibold ${
                    showContactDetails ? "active" : "hover:bg-[#A3A3A3]"
                  }`}
                  onClick={toggleContactDetails}
                >
                  Details
                </button>
                <button
                  className={`category-button text-xs text-center font-sans font-semibold ${
                    showCategories ? "active" : "hover:bg-[#A3A3A3]"
                  }`}
                  onClick={toggleCategories}
                >
                  Categories
                </button>
              </div>
              {showContactDetails &&
                ViewAccountDetails?.map((account) => (
                  <div className="flex flex-row justify-around mb-5 gap-5">
                    <div className="contact-detail-card p-5 h-[179px] flex-none">
                      <p className="text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 pb-5">
                        Contact Details
                      </p>
                      <div className="space-y-1">
                        <p className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100">
                          {"+91-" + account.contact_no}
                        </p>
                        <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 pb-2.5 border-b-2 border-solid border-[#1d3a6d]">
                          Contact number
                        </p>
                      </div>
                      <Tooltip
                        componentsProps={{
                          popper: {
                            sx: {
                              [`& .${tooltipClasses.tooltip}`]: {
                                backgroundColor: (theme) =>
                                  theme.palette.common.black,
                              },
                              [`& .${tooltipClasses.arrow}`]: {
                                color: (theme) => theme.palette.common.black,
                              },
                            },
                          },
                        }}
                        title={account.email_id}
                        arrow
                        placement="bottom"
                        PopperProps={{
                          style: {
                            maxWidth: "none",
                          },
                        }}
                      >
                        <div className="mt-2.5 space-y-1">
                          <div
                            id="email-id-firstLetter"
                            className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100"
                            style={{
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {account.email_id}
                          </div>
                          <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100">
                            Email ID
                          </p>
                        </div>
                      </Tooltip>
                    </div>

                    <div className="account-detail-card p-5 h-[179px] flex-none">
                      <p className="text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 pb-5">
                        Account Details
                      </p>
                      <div className="space-y-1">
                        <p className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100">
                          {account.pan_no}
                        </p>
                        <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 pb-2.5 border-b-2 border-solid border-[#1d3a6d]">
                          PAN details
                        </p>
                      </div>
                      <div
                        className={`gst-details mt-1.5 py-1 pl-3 pr-1 space-y-1 ${
                          account?.gstin_detail
                            ? "hover:bg-gray-200 hover:bg-opacity-20 rounded-lg -ml-2.5 py-1 pl-3 pr-1 cursor-pointer"
                            : "cursor-default"
                        }`}
                        onClick={() => handleGSTDetails(account.gstin_detail)}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100"
                            style={{ flex: "90%" }}
                          >
                            {account.gstin_detail
                              ? account.gstin_detail
                              : "Not Given"}
                          </div>
                          {account.gstin_detail ? (
                            <>
                              <div
                                onClick={() =>
                                  handleGSTDetails(account.gstin_detail)
                                }
                                style={{ flex: "10%" }}
                                className="grid place-content-center w-3 h-4.4 bg-[#1D3A6D] rounded-md"
                              >
                                <button>
                                  <img
                                    src={arrowRight}
                                    alt="document"
                                    className="w-3 h-3"
                                  />
                                </button>
                              </div>
                            </>
                          ) : (
                            <>
                              <div style={{ flex: "10%" }}></div>
                            </>
                          )}
                        </div>
                        <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100">
                          GST details
                        </p>
                      </div>
                    </div>

                    <div
                      className={`origin-timeline-card p-5 flex-none ${
                        account.approval_status === "rejected"
                          ? "h-auto"
                          : "h-[179px]"
                      }`}
                    >
                      <p className="text-sm text-[#DADADA] text-left font-sans font-semibold pb-5 opacity-100">
                        Origin Timeline
                      </p>
                      <div className="space-y-1">
                        <div className="flex justify-between">
                          <p className="creator-name text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate w-3/5">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={account.created_by_name}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  display: "block",
                                }}
                              >
                                {account.created_by_name}
                              </div>
                            </Tooltip>
                          </p>
                          <p className="w-2/5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                            {account.created_on
                              ? formatDateViewAll(account.created_on, false)
                              : "Not given"}
                          </p>
                        </div>
                        <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 w-full pb-2.5 border-b-2 border-solid border-[#1d3a6d]">
                          Created by
                        </p>
                      </div>

                      <div className="mt-2.5">
                        {account.approval_status === "approved" ||
                        account.approval_status === "rejected" ? (
                          <>
                            <div className="flex justify-between">
                              {account.approval_status === "approved" ? (
                                <>
                                  <p className="approved-name w-3/5 pb-1 text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate">
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={
                                        account.approved_by_name
                                          ? account.approved_by_name
                                          : "Not given"
                                      }
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                        }}
                                      >
                                        {account.approved_by_name
                                          ? account.approved_by_name
                                          : "Not given"}
                                      </div>
                                    </Tooltip>
                                  </p>
                                  <p className="w-2/5 mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                    {account.approved_on
                                      ? formatDateViewAll(
                                          account.approved_on,
                                          false
                                        )
                                      : "Not given"}
                                  </p>
                                </>
                              ) : account.rejected_by_name ? (
                                <>
                                  <p className="approved-name w-3/5 pb-1 text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate">
                                    <Tooltip
                                      componentsProps={{
                                        popper: {
                                          sx: {
                                            [`& .${tooltipClasses.tooltip}`]: {
                                              backgroundColor: (theme) =>
                                                theme.palette.common.black,
                                            },
                                            [`& .${tooltipClasses.arrow}`]: {
                                              color: (theme) =>
                                                theme.palette.common.black,
                                            },
                                          },
                                        },
                                      }}
                                      title={account.rejected_by_name}
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        style: {
                                          maxWidth: "none",
                                        },
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                          display: "block",
                                        }}
                                      >
                                        {account.rejected_by_name}
                                      </div>
                                    </Tooltip>
                                  </p>
                                  <p className="w-2/5 mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                    {account.rejected_on
                                      ? formatDateViewAll(
                                          account.rejected_on,
                                          false
                                        )
                                      : "Not given"}
                                  </p>
                                </>
                              ) : (
                                "Not given"
                              )}
                            </div>

                            <div
                              className={`text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100 ${
                                account.approval_status === "rejected"
                                  ? "border-b-2 border-solid border-[#1d3a6d] pb-2.5"
                                  : ""
                              }`}
                            >
                              {account.approval_status === "approved"
                                ? "Approved by"
                                : "Rejected by"}
                            </div>
                          </>
                        ) : (
                          <div className="space-y-1">
                            <div className="flex justify-between">
                              <div className="w-3/5 text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 truncate">
                                {account.approval_status === "pending" ? (
                                  "Approval pending"
                                ) : account.approval_status === "approved" ? (
                                  <>
                                    {account.approved_by_name
                                      ? account.approved_by_name
                                      : "Not given"}
                                  </>
                                ) : account.approval_status === "archived" ? (
                                  <>
                                    {account.archived === "Y" ? (
                                      <>
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={nameArchive}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              display: "block",
                                            }}
                                          >
                                            {nameArchive}
                                          </div>
                                        </Tooltip>
                                      </>
                                    ) : account.archived_by_name ? (
                                      <>
                                        <Tooltip
                                          componentsProps={{
                                            popper: {
                                              sx: {
                                                [`& .${tooltipClasses.tooltip}`]:
                                                  {
                                                    backgroundColor: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                                [`& .${tooltipClasses.arrow}`]:
                                                  {
                                                    color: (theme) =>
                                                      theme.palette.common
                                                        .black,
                                                  },
                                              },
                                            },
                                          }}
                                          title={account.archived_by_name}
                                          arrow
                                          placement="bottom"
                                          PopperProps={{
                                            style: {
                                              maxWidth: "none",
                                            },
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: "100%",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              display: "block",
                                            }}
                                          >
                                            {account.archived_by_name}
                                          </div>
                                        </Tooltip>
                                      </>
                                    ) : (
                                      "Not given"
                                    )}
                                  </>
                                ) : account.approval_status === "blocked" ? (
                                  <>
                                    {account.blocked_by_name ? (
                                      <Tooltip
                                        componentsProps={{
                                          popper: {
                                            sx: {
                                              [`& .${tooltipClasses.tooltip}`]:
                                                {
                                                  backgroundColor: (theme) =>
                                                    theme.palette.common.black,
                                                },
                                              [`& .${tooltipClasses.arrow}`]: {
                                                color: (theme) =>
                                                  theme.palette.common.black,
                                              },
                                            },
                                          },
                                        }}
                                        title={account.blocked_by_name}
                                        arrow
                                        placement="bottom"
                                        PopperProps={{
                                          style: {
                                            maxWidth: "none",
                                          },
                                        }}
                                      >
                                        <div
                                          style={{
                                            width: "100%",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            display: "block",
                                          }}
                                        >
                                          {account.blocked_by_name}
                                        </div>
                                      </Tooltip>
                                    ) : (
                                      "Not given"
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                              {account.approval_status === "approved" && (
                                <div className="mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                  {account.approved_on
                                    ? formatDateViewAll(
                                        account.approved_on,
                                        false
                                      )
                                    : "Not given"}
                                </div>
                              )}
                              {account.approval_status === "archived" && (
                                <div className="mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                  {account.archived_on
                                    ? formatDateViewAll(
                                        account.archived_on,
                                        false
                                      )
                                    : "Not given"}
                                </div>
                              )}
                              {account.approval_status === "blocked" && (
                                <div className="mt-0.5 text-[10px] text-[#C9C9C9] text-right font-sans font-normal opacity-100">
                                  {account.blocked_on
                                    ? formatDateViewAll(
                                        account.blocked_on,
                                        false
                                      )
                                    : "Not given"}
                                </div>
                              )}
                            </div>

                            <div className="text-xs text-[#C9C9C9] text-left font-sans font-normal">
                              {account.approval_status === "pending"
                                ? "Approved by"
                                : `${account.approval_status} by`}
                            </div>
                          </div>
                        )}
                        {account.approval_status === "rejected" && (
                          <div className="mt-2.5 space-y-1">
                            <div className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 break-words whitespace-normal w-full">
                              {account?.rejection_reason}
                            </div>
                            <div className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-1">
                              Rejected reason
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="support-information-card p-5 h-[179px] flex-none">
                      <p className="text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 pb-5">
                        Support Information
                      </p>
                      <div className="space-y-1">
                        <p className="text-sm text-[#FFFFFF] text-left font-sans font-normal opacity-100 make-text-lower">
                          {account.id}
                        </p>
                        <p className="text-xs text-[#C9C9C9] text-left font-sans font-normal opacity-100">
                          Account ID
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              {showCategories && (
                <div
                  className={`account-details-page-categories-card h-auto ${
                    viewAccountDetailData?.beneficiary_accounts?.[0]?.category
                      .length > 0
                      ? ""
                      : "w-1/2"
                  }`}
                >
                  <span
                    className={`text-sm text-[#DADADA] text-left font-sans font-semibold opacity-100 block mb-5 ${
                      viewAccountDetailData?.beneficiary_accounts?.[0]?.category
                        .length > 0
                        ? "ml-6"
                        : ""
                    }`}
                  >
                    {viewAccountDetailData?.beneficiary_accounts?.[0]?.category
                      .length > 0
                      ? "Assigned Beneficiary Categories"
                      : "No assigned beneficiary categories found"}
                  </span>

                  <div
                    className={`${
                      viewAccountDetailData?.beneficiary_accounts?.[0]?.category
                        .length > 0
                        ? "assigned-beneficiary-categories-data-div"
                        : ""
                    }`}
                  >
                    {viewAccountDetailData?.beneficiary_accounts.map(
                      (account, index) => (
                        <div key={index}>
                          <Box
                            sx={{
                              display: "grid",
                              gridTemplateColumns: "repeat(4, 1fr)",
                              gap: 2,
                            }}
                          >
                            {account.category
                              ? account.category
                                  .split(",")
                                  .map((category, categoryIndex) => (
                                    <div
                                      className="category-card flex flex-row items-center"
                                      key={categoryIndex}
                                    >
                                      <div className="bene-category-img flex justify-center items-center opacity-100">
                                        <img
                                          className="w-9 h-9 cateImage max-w-none"
                                          src={BeneIcon}
                                          alt="bene"
                                        />
                                      </div>
                                      <div className="truncate text-white px-3">
                                        <span className="text-sm text-[#FAFAFA] text-left font-sans font-semibold opacity-100 pl-1.5 antialiased">
                                          <Tooltip
                                            componentsProps={{
                                              popper: {
                                                sx: {
                                                  [`& .${tooltipClasses.tooltip}`]:
                                                    {
                                                      backgroundColor: (
                                                        theme
                                                      ) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                  [`& .${tooltipClasses.arrow}`]:
                                                    {
                                                      color: (theme) =>
                                                        theme.palette.common
                                                          .black,
                                                    },
                                                },
                                              },
                                            }}
                                            title={category.trim()}
                                            arrow
                                            placement="bottom"
                                            PopperProps={{
                                              style: {
                                                maxWidth: "none",
                                              },
                                            }}
                                          >
                                            {category.trim()}
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </div>
                                  ))
                              : null}
                          </Box>
                          {!account.category && (
                            <p
                              key={`notFound_${index}`}
                              className="antialiased text-white text-sm"
                            >
                              The beneficiary currently does not have any
                              assigned categories. Please click edit to assign a
                              category.
                            </p>
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default BeneAccDetails;
