import React from "react";
import { useSelector } from "react-redux";
import arrowRight from "../../../assets/Settings/Dashboard/arrow_right.png";

const currentEpoch = String(Math.floor(new Date().getTime() / 1000.0));

function ThreeUpcomingHolidays({ setSidebarState }) {
  
  return (
    <div
      onClick={() => setSidebarState(true)}
    >
      <div  style={{width:'90%',backgroundColor:"#314D7E", margin:"0px auto",borderRadius:"37px",padding:"10px 20px",cursor:"pointer"}}>
      <div className="flex gap-3 justify-center items-center pr-4">          
          <div className="text-white font-semibold">Upcoming Bank Holidays</div>
          <div className="w-4 h-8 flex items-center">
            <img src={arrowRight} alt="" />
          </div>
      </div>
      </div>
    </div>
  );
}

export default ThreeUpcomingHolidays;
