import React, { useState, useEffect, useRef } from "react";
import Session from "../../../assets/svg/sessionTimeout.svg";
import Input from "@mui/material/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
} from "@mui/material";
import {
  loginNewToken,
  setMultipleEntity,
  setShowReloginModalFalse,
} from "../../../redux/features/login/login";
import { logout } from "../../../redux/features/refreshToken/refreshToken";
import LoaderIcon from "../../../assets/Beneficiary Icons/inputloder.gif";
import whiteEyeOff from "../../../assets/loginImage/whiteEyeOff.svg";
import whiteEyeOn from "../../../assets/loginImage/whiteEyeOn.svg";

const ReLoginModal = ({ setShowModalPopup, isLoading, isError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const reLoginInputRef = useRef(null);
  const [previousUserId, setPreviousUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [status, setStatus] = useState("");
  const [cred, setCred] = useState({
    user_name: previousUserId,
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isClicked, setIsclicked] = useState(false);
  const [isReLoggingIn, setIsReLoggingIn] = useState(false);
  const token = window.sessionStorage.getItem("q2p_token");
  const loggedIn = sessionStorage.getItem("loggedIn");
  const { multipleEntity } = useSelector((state) => state.login);

  const handleClickShowPassword = () => {
    const input = reLoginInputRef.current;
    const cursorPosition = input.selectionStart;

    setShowPassword((show) => !show);
    setTimeout(() => {
      input.setSelectionRange(cursorPosition, cursorPosition);
      input.focus();
    }, 0);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if ((token || loggedIn) && isClicked) {
      if (window.location.pathname == "/home") {
        navigate("/login");
      } else {
        navigate("/home");
      }
    }
  }, [token, loggedIn, multipleEntity]);

  const handleChange = (field, value) => {
    const sanitizedValue = value.replace(/\s/g, "");
    setCred((prevCred) => ({ ...prevCred, [field]: sanitizedValue }));
  };

  const isCredentialsFilled = cred.password.trim() !== "";

  useEffect(() => {
    const storedUserId = localStorage.getItem("user_id");
    setPreviousUserId(storedUserId);
    setCred((prevCred) => ({ ...prevCred, user_name: storedUserId || "" }));
  }, []);

  useEffect(() => {
    const storedfirstName = localStorage.getItem("first_name");
    setFirstName(storedfirstName);
  }, []);

  useEffect(() => {
    const storedStatus = sessionStorage.getItem("status");
    setStatus(storedStatus);
  }, []);

  if (isLoading && !isError) {
    return (
      <div className="loader-container w-full h-full bg-[#000] bg-opacity-80 justify-center items-center ">
        <img
          style={{
            height: "100px",
            width: "100px",
          }}
          src={LoaderIcon}
        />
      </div>
    );
  }

  const handleReLogin = () => {
    setIsReLoggingIn(true);
    setIsclicked(true);
    dispatch(loginNewToken(cred)).finally(() => {
      setIsReLoggingIn(false);
    });
  };
  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter" && isCredentialsFilled) {
      handleReLogin();
    }
  };

  if (location.pathname === "/login" || location.pathname === "/") {
    return null;
  }

  return (
    <>
      <div className="fixed w-screen top-0 left-0 h-screen bg-custom-backdrop opacity-80 z-[2147483647]"></div>
      <div
        className="flex justify-center items-center m-auto top-1/2 left-1/2 fixed z-[2147483647]"
        style={{ transform: "translate(-50%, -50%)" }}
      >
        <div className="h-fit w-[400px] flex flex-col gap-2 py-4 px-2 bg-custom-appBackLight rounded-2xl">
          <div className="mx-4">
            <h6 className="text-white pb-1">Hi {firstName}, </h6>
            <div className="flex items-center pb-2">
              <img src={Session} alt="sessiontimeout" className="w-4 h-4" />
              <span
                className="text-custom-orange font-semibold ml-2"
                style={{
                  fontSize: window.innerWidth <= 600 ? "12px" : "16px",
                }}
              >
                Your session has timed out
              </span>
            </div>
            <div className="mb-3">
              <p className="text-white font-bold">{previousUserId}</p>
              <span
                className="text-white text-sm"
                style={{
                  fontFamily: "Open Sans",
                  lineHeight: "19px",
                }}
              >
                User ID
              </span>
            </div>

            <FormControl sx={{ mt: "1", width: "100%" }} variant="standard">
              <InputLabel
                htmlFor="standard-adornment-password"
                sx={{
                  color: "#fff !important",
                  fontSize: "16px",
                  "&.Mui-focused": {
                    color: "#fff !important",
                  },
                }}
                style={{
                  fontFamily: "Open Sans",
                  lineHeight: "19px",
                }}
              >
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      <img
                        src={showPassword ? whiteEyeOn : whiteEyeOff}
                        alt={showPassword ? "Hide password" : "Show password"}
                        style={{ width: "18px", height: "18px" }}
                      />
                    </IconButton>
                  </InputAdornment>
                }
                value={cred.password}
                onChange={(e) => handleChange("password", e.target.value)}
                onKeyDown={handleEnterKeyPress}
                inputRef={reLoginInputRef}
                sx={{
                  fontSize: !showPassword ? "10px" : "16px",
                  color: "#fff !important",
                  padding: "3px !important",
                  borderBottomColor: "#fff",
                  "&.MuiInput-underline:before": {
                    borderBottomColor: "#fff",
                  },
                  "&.MuiInput-underline:after": {
                    borderBottomColor: "#fff",
                  },
                  "&:hover": {
                    color: "#fff",
                    borderBottomColor: "#fff",
                    "&.MuiInput-underline:before": {
                      borderBottomColor: "#fff",
                    },
                    "&.MuiInput-underline:after": {
                      borderBottomColor: "#fff",
                    },
                  },
                }}
              />
            </FormControl>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "6%",
              margin: "0px 50px",
              width: "100%",
            }}
            className="px-4 pt-4 m-4"
          >
            {!isReLoggingIn && (
              <Button
                sx={{
                  borderColor: "none",
                  color: "#fff",
                  textTransform: "capitalize",
                  textDecoration: "underline",
                }}
                style={{
                  fontFamily: "Open Sans",
                  lineHeight: "19px",
                }}
                onClick={() => {
                  dispatch(setMultipleEntity());
                  dispatch(logout());
                  dispatch(setShowReloginModalFalse());
                  setShowModalPopup(false);
                  navigate("/login");
                }}
              >
                Logout
              </Button>
            )}

            <Button
              sx={{
                borderRadius: "20px",
                color: "#fff",
                boxShadow: "0px 3px 6px #00000033",
                textTransform: "capitalize",
                border: "1px solid transparent",
                backgroundColor: isCredentialsFilled ? "#69B76F" : "#716F70",
                pointerEvents:
                  isCredentialsFilled && !isReLoggingIn ? "auto" : "none",
                cursor:
                  isCredentialsFilled && !isReLoggingIn ? "pointer" : "default",
                "&:hover": {
                  backgroundColor: "#69B76F",
                  boxShadow: "0px 3px 6px #00000029",
                  border: "1px solid transparent",
                },
                marginRight: "40px",
                width: "110px",
              }}
              style={{
                fontFamily: "Open Sans",
                lineHeight: "19px",
              }}
              onClick={handleReLogin}
            >
              {isReLoggingIn ? (
                <img
                  src={LoaderIcon}
                  alt="Loading"
                  style={{
                    width: "20px",
                    height: "20px",
                  }}
                />
              ) : (
                "Login"
              )}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReLoginModal;
