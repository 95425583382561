import React, { useEffect, useState } from "react";
import successImage from "../../../../assets/NewImg.png";
import createTransaction from "../../../../assets/create_transaction.png";
import viewDetails from "../../../../assets/view_details.png";
import dashboard from "../../../../assets/Dashboard.png";
import ContactNumber from "../../../../assets/Beneficiary Icons/Contact_Number.png";
import Email from "../../../../assets/Beneficiary Icons/Email.png";
import { beneSuccessDetail } from "../../../../redux/features/accounts/BeneficiaryAccount/createAccount";
import { checkUsertoken } from "../../../../redux/features/login/login";
import { useDispatch, useSelector } from "react-redux";
import { json, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tooltip, tooltipClasses } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { formatAccountNumber } from "../../../../components/_utils/FormatAccountNumber";
import {
  resetInEdit,
  resetVerification,
} from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneLookUp } from "../../../../redux/features/verifyPAN/verifyPAN";
import IfscCodeMapper from "../../../../components/_utils/IfscLogoMapper";
import "./index.css";

const BenePanEditSucess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [currentTab, setCurrentTab] = useState(0);
  const [previousAssociated, setPreviousAssociated] = useState(0);
  const [gstData, setGstData] = useState({
    id: "",
    panNo: "",
    gstNo: "",
  });
  const [currentAccountDetails, setCurrentAccountDetails] = useState({
    id: "",
    name: "",
    bank_name: "",
    bank_acc_no: "",
    ifsc_code: "",
    category: "",
    pan: "",
  });

  useEffect(() => {
    dispatch(resetVerification());
  }, []);

  useEffect(() => {
    dispatch(checkUsertoken());
    const fetchPANSuccessDetail = async () => {
      let response = await dispatch(beneSuccessDetail(id));
      if (response?.error?.message === "Request failed with status code 404") {
        navigate("*");
      }
    };
    fetchPANSuccessDetail();
  }, [dispatch, id, navigate]);

  useEffect(() => {
    const storedValue = localStorage.getItem("haveAssociated");
    if (storedValue !== null) {
      setPreviousAssociated(JSON.parse(storedValue));
    }
    // return () => {
    //   localStorage.removeItem("haveAssociated");
    // };
  }, []);

  const { beneLookData, beneLookLoading, verifyPANisLoading } = useSelector(
    (state) => state.verify
  );
  const { isSuccessLoading } = useSelector((state) => state.beneCreateAccount);

  const successDetail = useSelector(
    (state) => state.beneCreateAccount.beneSuccessData
  );

  const beneficiaryAccount = successDetail?.beneficiary_accounts[0] || {};

  useEffect(() => {
    if (beneficiaryAccount) {
      const { id, name, bank_name, bank_acc_no, ifsc_code, category, pan_no } =
        beneficiaryAccount;
      setCurrentAccountDetails({
        id,
        name,
        bank_name,
        bank_acc_no,
        ifsc_code,
        category,
        pan: pan_no,
      });
      sessionStorage.setItem("ActualPANno", beneficiaryAccount?.pan_no);
      sessionStorage.setItem("ActualGstno", beneficiaryAccount?.gstin_detail);
    }
  }, [beneficiaryAccount]);

  const createTxn = () => {
    navigate("/transaction/beneficiary_transaction/create", {
      state: currentAccountDetails,
    });
  };

  const viewDetailsHandler = () => {
    navigate(`/accounts/beneficiary_account/details/${id}`);
  };
  const dashboardHandler = () => {
    navigate("/accounts/beneficiary/dashboard");
  };

  const bgColorClass = currentTab === 0 ? "bg-[#55985C]" : "bg-[#4C8452]";

  let haveApproved = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "approved";
  });
  let havePending = beneLookData?.beneficiary_accounts.find((val) => {
    return val?.approval_status === "pending" && val?.id !== id;
  });

  useEffect(() => {
    if (!isSuccessLoading && successDetail) {
      dispatch(checkUsertoken());
      dispatch(
        beneLookUp({
          pan_number: successDetail?.beneficiary_accounts[0]?.pan_no,
        })
      );
    }
    return () => {
      dispatch(resetInEdit());
    };
  }, [successDetail]);

  useEffect(() => {
    let data = sessionStorage.getItem("Pan Gst Verification");
    setGstData(JSON.parse(data));
  }, []);

  return (
    <div className="min-h-screen bg-[#457549] pb-10">
      <div className="flex justify-center">
        <div className="flex flex-row max-w-screen-lg justify-center">
          <div className="flex pt-48">
            <div className="flex min-w-screen mx-5 justify-center items-center w-[450px] h-[450px]">
              <div className="p-6 flex flex-col items-center justify-center text-center text-white text-2xl">
                <img
                  src={successImage}
                  width={"100%"}
                  height={"100%"}
                  alt=""
                  srcSet=""
                />
                <div className="pt-7">
                  <p className="text-xl">
                    {gstData.panNo != beneficiaryAccount?.pan_no &&
                    gstData?.gstNo != beneficiaryAccount?.gstin_detail
                      ? "PAN and GST "
                      : gstData?.gstNo != beneficiaryAccount?.gstin_detail
                      ? "GST "
                      : gstData.panNo != beneficiaryAccount?.pan_no
                      ? "PAN "
                      : "PAN and GST "}
                    number updated successfully!
                  </p>
                </div>
              </div>
            </div>
          </div>

          {!beneLookLoading && beneLookData && (
            <>
              {previousAssociated === false &&
              (haveApproved !== undefined || havePending !== undefined) ? (
                <div className="flex-1 pt-32">
                  <div
                    className={`mx-5 font-semibold text-xs flex flex-row justify-between py-0 pb-0  rounded-t-[30px] items-center ${bgColorClass}`}
                  >
                    <Tabs
                      value={currentTab}
                      onChange={(event, newValue) => setCurrentTab(newValue)}
                      TabIndicatorProps={{
                        style: { backgroundColor: "transparent" },
                      }}
                      sx={{
                        "& .MuiTab-root": {
                          fontSize: "10px",
                          color: "#F9F9F9",
                        },
                        "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                          color: "#F9F9F9",
                        },
                        "& .MuiTabs-flexContainer": {
                          position: "relative",
                        },
                        "& .MuiTab-root": {
                          position: "relative",
                          zIndex: 1,
                          "&::after": {
                            content: "''",
                            position: "absolute",
                            height: "33px",
                            width: "1px",
                            backgroundColor: "#F9F9F9",
                            top: 13.5,
                            right: 0,
                          },
                          "&:last-child::after": {
                            display: "none",
                          },
                        },
                      }}
                    >
                      <Tab
                        label={
                          <span
                            style={{
                              fontFamily: "Open Sans, sans-serif",
                              fontWeight: currentTab === 0 ? 600 : 400,
                            }}
                          >
                            Account Details
                          </span>
                        }
                        sx={{
                          color: currentTab === 0 ? "#F9F9F9" : "#F9F9F9",
                          width: "237px",
                          textTransform: "none",
                          height: "60px",
                          borderTopLeftRadius: "30px",
                          backgroundColor:
                            currentTab === 0 ? "#55985C" : "#4C8452",
                        }}
                      />

                      <Tab
                        label={
                          <span
                            style={{
                              whiteSpace: "pre-line",
                              fontFamily: "Open Sans, sans-serif",
                              fontWeight: currentTab === 1 ? 600 : 400,
                            }}
                          >
                            Accounts Associated{"\n"}to PAN
                          </span>
                        }
                        sx={{
                          color: currentTab === 1 ? "#F9F9F9" : "#F9F9F9",
                          width: "241px",
                          textTransform: "none",
                          height: "60px",
                          borderTopRightRadius: "30px",
                          paddingTop: "13px",
                          backgroundColor:
                            currentTab === 1 ? "#55985C" : "#4C8452",
                        }}
                      />
                    </Tabs>
                  </div>

                  {currentTab === 0 ? (
                    <div
                      className=" flex flex-col mx-5 bg-white rounded-b-4xl items-center justify-between"
                      style={{ height: "518px" }}
                    >
                      <div
                        className="w-full flex flex-col gap-2 items-center justify-center overflow-y-auto h-80 custom-scroll-success-container"
                        style={{ height: "360px" }}
                      >
                        <div
                          className={`flex flex-col gap-2 font-bold ${
                            beneficiaryAccount?.gstin_detail
                              ? "pt-[32%]"
                              : "pt-[20%]"
                          } px-7`}
                        >
                          <div className="text-base text-[#000000] w-full text-center pb-2">
                            <p
                              className="w-96"
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: " nowrap",
                                textTransform: "capitalize",
                              }}
                            >
                              {beneficiaryAccount?.name}
                            </p>
                          </div>
                        </div>

                        <div className="w-full content-center">
                          <div className="ml-5 mr-2 dashedLine"></div>
                        </div>
                        <div className="detailsText">
                          <div className="flex flex-col space-y-1 text-left">
                            <div className="text-base text-black antialiased">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                arrow
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                                title={beneficiaryAccount.category}
                                placement="bottom-start"
                              >
                                <div className="font-normal text-base capitalize">
                                  <div
                                    className="w-96"
                                    style={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      textAlign: "start",
                                    }}
                                  >
                                    {typeof beneficiaryAccount.category ===
                                    "string"
                                      ? beneficiaryAccount.category
                                          .split(",")
                                          .map((category) => category.trim())
                                          .join(" , ")
                                      : beneficiaryAccount.category}
                                  </div>
                                </div>
                              </Tooltip>
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              Assigned categories
                            </div>
                          </div>
                          <div className="simpleLine bg-[#A0A0A0]"></div>
                          <div className="flex flex-col space-y-1 text-left">
                            <div className="text-base text-black antialiased">
                              {beneficiaryAccount?.pan_no}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              PAN number
                            </div>
                          </div>
                          <div className="simpleLine bg-[#A0A0A0]"></div>
                          <div className="flex flex-col space-y-1 text-left">
                            <div className="text-base text-black antialiased">
                              {formatAccountNumber(
                                beneficiaryAccount?.bank_acc_no
                              )}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              Account number
                            </div>
                          </div>
                          <div className="simpleLine bg-[#A0A0A0]"></div>
                          <div className="flex flex-row justify-between py-0.5">
                            <div className="flex flex-col space-y-1 text-left w-4/6">
                              <div className="text-base text-black antialiased">
                                {beneficiaryAccount?.ifsc_code}
                              </div>
                              <div className="text-gray-500 text-xs antialiased">
                                IFSC code
                              </div>
                            </div>
                            <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                              <img
                                src={require("../../../../assets/IfscLogo/" +
                                  IfscCodeMapper(
                                    beneficiaryAccount?.ifsc_code
                                  ))}
                                alt="bank"
                              />
                            </div>
                          </div>
                          <div className="simpleLine bg-[#A0A0A0]"></div>
                          <div className="flex flex-row justify-between py-0.5">
                            <div className="flex flex-col space-y-1 text-left w-4/6">
                              <div className="text-base text-black antialiased">
                                +91&nbsp;{beneficiaryAccount?.contact_no}
                              </div>
                              <div className="text-gray-500 text-xs antialiased">
                                Contact number
                              </div>
                            </div>
                            <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                              <img src={ContactNumber} alt="Contact Number" />
                            </div>
                          </div>
                          <div className="simpleLine bg-[#A0A0A0]"></div>
                          <div className="flex flex-row justify-between py-0.5">
                            <div className="flex flex-col space-y-1 text-left w-4/6">
                              <Tooltip
                                componentsProps={{
                                  popper: {
                                    sx: {
                                      [`& .${tooltipClasses.tooltip}`]: {
                                        backgroundColor: (theme) =>
                                          theme.palette.common.black,
                                      },
                                      [`& .${tooltipClasses.arrow}`]: {
                                        color: (theme) =>
                                          theme.palette.common.black,
                                      },
                                    },
                                  },
                                }}
                                title={beneficiaryAccount?.email_id}
                                arrow
                                placement="bottom"
                                leaveTouchDelay={0}
                                PopperProps={{
                                  style: {
                                    maxWidth: "none",
                                  },
                                }}
                              >
                                <div
                                  className="text-base font-medium text-black antialiased"
                                  style={{
                                    width: "50%",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {beneficiaryAccount?.email_id}
                                </div>
                              </Tooltip>

                              <div className="text-gray-500 text-xs antialiased">
                                Email ID
                              </div>
                            </div>
                            <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                              <img src={Email} alt="Email" />
                            </div>
                          </div>
                          {beneficiaryAccount?.gstin_detail && (
                            <>
                              <div className="simpleLine bg-[#A0A0A0]"></div>
                              <div className="flex flex-row justify-between py-0.5">
                                <div className="flex flex-col space-y-1 text-left w-4/6">
                                  <div className="text-base text-black antialiased">
                                    {beneficiaryAccount.gstin_detail}
                                  </div>
                                  <div className="text-gray-500 text-xs antialiased">
                                    GST
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        style={{ height: "135px" }}
                        className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[30px] bg-[#55985C] flex justify-evenly items-center gap-0"
                      >
                        <div
                          className="flex flex-col space-y-1 w-2/6 gap-2 items-center justify-center text-center border-gray-300"
                          onClick={createTxn}
                        >
                          <div className="w-3/6 flex justify-center">
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={createTransaction}
                              alt=""
                              srcSet=""
                            />
                          </div>
                          <p className="text-xs">
                            Create
                            <br />
                            transaction
                          </p>
                        </div>
                        <div
                          className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                          onClick={viewDetailsHandler}
                        >
                          <div className="w-3/6 flex justify-center">
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={viewDetails}
                              alt=""
                              srcSet=""
                            />
                          </div>
                          <p className="text-xs">
                            View
                            <br />
                            details
                          </p>
                        </div>
                        <div
                          className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                          onClick={dashboardHandler}
                        >
                          <div className="w-3/6 flex justify-center">
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={dashboard}
                              alt=""
                              srcSet=""
                            />
                          </div>
                          <p className="text-xs">
                            Go to <br /> dashboard
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className=" flex flex-col mx-5 bg-white rounded-b-4xl justify-between"
                      style={{ height: "518px" }}
                    >
                      {haveApproved || havePending ? (
                        <div className="w-full max-h-[380px] overflow-y-auto">
                          {haveApproved && (
                            <>
                              <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-4 mb-2 px-8 py-0.5">
                                Approved accounts
                              </p>
                              <div className="flex flex-col w-full px-8">
                                {beneLookData?.beneficiary_accounts.map(
                                  (val, index, array) => {
                                    if (val?.approval_status === "approved") {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className="group flex flex-col drop-shadow-xl pb-2 overflow-hidden"
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                },
                                              }}
                                              title={val.name.toLowerCase()}
                                              placement="bottom-start"
                                            >
                                              <div className="font-normal text-base capitalize">
                                                <div
                                                  className="w-80"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    textAlign: "start",
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                </div>
                                              </div>
                                            </Tooltip>

                                            <div className="flex flex-row justify-between">
                                              <div className="flex flex-col space-y-1 text-left">
                                                <div className="w-[16rem] text-base text-black antialiased overflow-ellipsis overflow-hidden whitespace-nowrap">
                                                  <Tooltip
                                                    componentsProps={{
                                                      popper: {
                                                        sx: {
                                                          [`& .${tooltipClasses.tooltip}`]:
                                                            {
                                                              backgroundColor: (
                                                                theme
                                                              ) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                          [`& .${tooltipClasses.arrow}`]:
                                                            {
                                                              color: (theme) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                        },
                                                      },
                                                    }}
                                                    title={formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                    PopperProps={{
                                                      style: {
                                                        maxWidth: "none",
                                                      },
                                                    }}
                                                    placement="bottom-start"
                                                  >
                                                    {formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                  </Tooltip>

                                                  <br />
                                                  <span className="text-xs text-[#828282]">
                                                    Bank account number
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-end w-2/6 h-4">
                                                <img
                                                  src={require("../../../../assets/IfscLogo/" +
                                                    IfscCodeMapper(
                                                      val?.ifsc_code
                                                    ))}
                                                  alt="bank"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {index !== array.length - 1 && (
                                            <div className="simpleLine bg-[#A0A0A0]"></div>
                                          )}
                                        </>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </div>
                            </>
                          )}
                          {havePending && (
                            <>
                              <p className="text-[#000000] text-sm font-semibold bg-[#DADADA] mt-2 px-8 py-0.5">
                                Pending accounts
                              </p>
                              <div className="flex flex-col w-full px-8 mt-3">
                                {beneLookData?.beneficiary_accounts.map(
                                  (val, index, array) => {
                                    if (
                                      val?.approval_status === "pending" &&
                                      val?.id !== id
                                    ) {
                                      return (
                                        <>
                                          <div
                                            key={index}
                                            className="group flex flex-col drop-shadow-xl pb-2 overflow-hidden"
                                          >
                                            <Tooltip
                                              componentsProps={{
                                                popper: {
                                                  sx: {
                                                    [`& .${tooltipClasses.tooltip}`]:
                                                      {
                                                        backgroundColor: (
                                                          theme
                                                        ) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                    [`& .${tooltipClasses.arrow}`]:
                                                      {
                                                        color: (theme) =>
                                                          theme.palette.common
                                                            .black,
                                                      },
                                                  },
                                                },
                                              }}
                                              arrow
                                              PopperProps={{
                                                style: {
                                                  maxWidth: "none",
                                                },
                                              }}
                                              title={val.name.toLowerCase()}
                                              placement="bottom-start"
                                            >
                                              <div className="font-normal text-base capitalize">
                                                <div
                                                  className="w-80"
                                                  style={{
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    textAlign: "start",
                                                  }}
                                                >
                                                  {val.name.toLowerCase()}
                                                </div>
                                              </div>
                                            </Tooltip>
                                            <div className="flex flex-row justify-between">
                                              <div className="flex flex-col space-y-1 text-left">
                                                <div className="w-[16rem] text-base text-black antialiased overflow-ellipsis overflow-hidden whitespace-nowrap">
                                                  <Tooltip
                                                    componentsProps={{
                                                      popper: {
                                                        sx: {
                                                          [`& .${tooltipClasses.tooltip}`]:
                                                            {
                                                              backgroundColor: (
                                                                theme
                                                              ) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                          [`& .${tooltipClasses.arrow}`]:
                                                            {
                                                              color: (theme) =>
                                                                theme.palette
                                                                  .common.black,
                                                            },
                                                        },
                                                      },
                                                    }}
                                                    title={formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                    PopperProps={{
                                                      style: {
                                                        maxWidth: "none",
                                                      },
                                                    }}
                                                    placement="bottom-start"
                                                  >
                                                    {formatAccountNumber(
                                                      val.bank_acc_no
                                                    )}
                                                  </Tooltip>
                                                  <br />
                                                  <span className="text-xs text-[#828282]">
                                                    Bank account number
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="flex flex-row justify-end w-2/6 h-4">
                                                <img
                                                  src={require("../../../../assets/IfscLogo/" +
                                                    IfscCodeMapper(
                                                      val?.ifsc_code
                                                    ))}
                                                  alt="bank"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          {array.filter(
                                            (acc) =>
                                              acc.approval_status === "pending"
                                          ).length -
                                            1 !==
                                            index && (
                                            <div className="simpleLine bg-[#A0A0A0]"></div>
                                          )}
                                        </>
                                      );
                                    }
                                    return null;
                                  }
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      ) : (
                        <div className="w-full h-full flex flex-col justify-center items-center gap-5 text-center overflow-hidden">
                          <div className="w-[40%] flex flex-row justify-center">
                            <img
                              className="max-w-[145%] pt-5"
                              alt="NoDataImg"
                            />
                          </div>
                          <div className="text-white text-lg antialiased">
                            <p>
                              No associated accounts found for the PAN details
                              provided
                              <br />
                              <span className="text-xs">
                                There are no existing accounts that are
                                associated with the PAN details provided.
                              </span>
                            </p>
                          </div>
                        </div>
                      )}

                      <div
                        style={{ height: "135px" }}
                        className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[30px] bg-[#55985C] flex justify-evenly items-center gap-0"
                      >
                        <div
                          className="flex flex-col space-y-1 w-2/6 h-3/4 gap-2 items-center justify-center text-center border-gray-300"
                          onClick={createTxn}
                        >
                          <div className="w-3/6 flex justify-center">
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={createTransaction}
                              alt=""
                              srcSet=""
                            />
                          </div>
                          <p className="text-xs">
                            Create
                            <br />
                            transaction
                          </p>
                        </div>
                        <div
                          className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                          onClick={viewDetailsHandler}
                        >
                          <div className="w-3/6 flex justify-center">
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={viewDetails}
                              alt=""
                              srcSet=""
                            />
                          </div>
                          <p className="text-xs">
                            View
                            <br />
                            details
                          </p>
                        </div>
                        <div
                          className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                          onClick={dashboardHandler}
                        >
                          <div className="w-3/6 flex justify-center">
                            <img
                              width={"40px"}
                              height={"40px"}
                              src={dashboard}
                              alt=""
                              srcSet=""
                            />
                          </div>
                          <p className="text-xs">
                            Go to <br /> dashboard
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex-1 pt-32">
                  <div
                    className={`mx-5 font-semibold text-xs flex flex-row justify-between py-0 pb-0  rounded-t-[30px] items-center ${bgColorClass}`}
                  >
                    <Tabs
                      value={currentTab}
                      onChange={(event, newValue) => setCurrentTab(newValue)}
                      TabIndicatorProps={{
                        style: { backgroundColor: "transparent" },
                      }}
                      sx={{
                        "& .MuiTab-root": {
                          fontSize: "10px",
                          color: "#F9F9F9",
                        },
                        "& .MuiButtonBase-root.MuiTab-root.Mui-selected": {
                          color: "#F9F9F9",
                        },
                        "& .MuiTabs-flexContainer": {
                          position: "relative",
                        },
                        "& .MuiTab-root": {
                          position: "relative",
                          zIndex: 1,
                          "&::after": {
                            content: "''",
                            position: "absolute",
                            height: "33px",
                            width: "1px",
                            backgroundColor: "#F9F9F9",
                            top: 13.5,
                            right: 0,
                          },
                          "&:last-child::after": {
                            display: "none",
                          },
                        },
                      }}
                    >
                      <Tab
                        label="Account Details"
                        sx={{
                          color: currentTab === 0 ? "#F9F9F9" : "#F9F9F9",
                          width: "472px",
                          textTransform: "none",
                          height: "60px",
                          borderTopLeftRadius: "30px",
                          backgroundColor:
                            currentTab === 0 ? "#55985C" : "#4C8452",
                          paddingLeft: "120px",
                        }}
                      />
                    </Tabs>
                  </div>

                  <div
                    className=" flex flex-col mx-5 bg-white rounded-b-4xl items-center justify-between "
                    style={{ height: "518px" }}
                  >
                    <div
                      className="w-full flex flex-col gap-2 items-center justify-center overflow-y-auto h-80"
                      style={{ height: "360px", marginTop: "20px" }}
                    >
                      <div
                        className={`flex flex-col font-bold ${
                          beneficiaryAccount?.gstin_detail
                            ? "pt-[20%]"
                            : "pt-[20%]"
                        } px-7`}
                      >
                        <div className="text-base text-[#000000] w-full text-center pb-2">
                          <p
                            className="w-80 lowercase first-line:capitalize"
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: " nowrap",
                            }}
                          >
                            {beneficiaryAccount?.name}
                          </p>
                        </div>
                      </div>

                      <div className="w-full content-center">
                        <div className="ml-5 mr-2 dashedLine"></div>
                      </div>
                      <div className="editpan-success-page">
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              title={beneficiaryAccount.category}
                              placement="bottom-start"
                            >
                              <div className="font-normal text-base capitalize">
                                <div
                                  className="w-80"
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    textAlign: "start",
                                  }}
                                >
                                  {typeof beneficiaryAccount.category ===
                                  "string"
                                    ? beneficiaryAccount.category
                                        .split(",")
                                        .map((category) => category.trim())
                                        .join(" , ")
                                    : beneficiaryAccount.category}
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Assigned categories
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {beneficiaryAccount?.pan_no}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            PAN number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-col space-y-1 text-left">
                          <div className="text-base text-black antialiased">
                            {formatAccountNumber(
                              beneficiaryAccount?.bank_acc_no
                            )}
                          </div>
                          <div className="text-gray-500 text-xs antialiased">
                            Account number
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              {beneficiaryAccount?.ifsc_code}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              IFSC code
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img
                              src={require("../../../../assets/IfscLogo/" +
                                IfscCodeMapper(beneficiaryAccount?.ifsc_code))}
                              alt="bank"
                            />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <div className="text-base text-black antialiased">
                              +91&nbsp;{beneficiaryAccount?.contact_no}
                            </div>
                            <div className="text-gray-500 text-xs antialiased">
                              Contact number
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={ContactNumber} alt="Contact Number" />
                          </div>
                        </div>
                        <div className="simpleLine bg-[#A0A0A0]"></div>
                        <div className="flex flex-row justify-between py-0.5">
                          <div className="flex flex-col space-y-1 text-left w-4/6">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              title={beneficiaryAccount?.email_id}
                              arrow
                              placement="bottom"
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                            >
                              <div
                                className="text-base font-medium text-black antialiased"
                                style={{
                                  width: "50%",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {beneficiaryAccount?.email_id}
                              </div>
                            </Tooltip>

                            <div className="text-gray-500 text-xs antialiased">
                              Email ID
                            </div>
                          </div>
                          <div className="flex flex-row justify-end w-2/6 h-5 mt-3">
                            <img src={Email} alt="Email" />
                          </div>
                        </div>
                        {beneficiaryAccount?.gstin_detail && (
                          <>
                            <div className="simpleLine bg-[#A0A0A0]"></div>
                            <div className="flex flex-row justify-between py-0.5">
                              <div className="flex flex-col space-y-1 text-left w-4/6">
                                <div className="text-base text-black antialiased">
                                  {beneficiaryAccount.gstin_detail}
                                </div>
                                <div className="text-gray-500 text-xs antialiased">
                                  GST
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      style={{ height: "135px" }}
                      className="w-full divide-x-2 py-7 cursor-pointer text-white rounded-[30px] bg-[#55985C] flex justify-evenly items-center gap-0"
                    >
                      <div
                        className="flex flex-col space-y-1 w-2/6 gap-2 items-center justify-center text-center border-gray-300"
                        onClick={createTxn}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={createTransaction}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Create
                          <br />
                          transaction
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={viewDetailsHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={viewDetails}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          View
                          <br />
                          details
                        </p>
                      </div>
                      <div
                        className="flex flex-col space-y-1 w-2/6 h-[76px] gap-2 items-center justify-center text-center border-gray-300"
                        onClick={dashboardHandler}
                      >
                        <div className="w-3/6 flex justify-center">
                          <img
                            width={"40px"}
                            height={"40px"}
                            src={dashboard}
                            alt=""
                            srcSet=""
                          />
                        </div>
                        <p className="text-xs">
                          Go to <br /> dashboard
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default BenePanEditSucess;
