import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setHideBreadCrumb,
  setCurrentMenu,
} from "../../redux/features/hideBreadCrumb/hideBreadCrumb";
import { checkUsertoken } from "../../redux/features/login/login";
import { CSSTransition } from "react-transition-group";
import PrevIcon from "../../assets/Beneficiary Icons/PreviousIcon.png";
import NextIcon from "../../assets/Beneficiary Icons/NextIcon.png";
import { getHolidays } from "../../redux/features/date/date";
import entityIcon from "../../assets/newdashboardicon/entity_das.png";
import dateIcon from "../../assets/newdashboardicon/date_icon.png";
import arrowright from "../../assets/Accounts/Create/arrowright.png";
import arrowleft from "../../assets/Beneficiary Icons/arrowleft.png";
import ProfileIcon from "../../../src/assets/ProfileIcon.png";
import Transactionicon from "../../assets/newdashboardicon/transactionicon.png";
import Accounticon from "../../assets/newdashboardicon/accounticon.png";
import payicon from "../../assets/newdashboardicon/transaction_pay_blue_circle.png";
import receiveicon from "../../assets/newdashboardicon/transaction_recieve_blue_circle.png";
import approvalicon from "../../assets/newdashboardicon/transaction_approve_blue_circle.png";
import createicon from "../../assets/newdashboardicon/create_account_blue_circle.png";
import beneficiaryicon from "../../assets/newdashboardicon/beneficiary_transaction_blue_circle.png";
import internalicon from "../../assets/newdashboardicon/internal_transaction_blue_circle.png";
import HolidaysSidebarnew from "../../components/HomeDashboard/HolidaysSidebarnew";
import { beneTxnViewAllAcc } from "../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { beneViewAllAcc } from "../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import formatTotalBalance from "../../utils/formatTotalBalance";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../assets/Beneficiary Icons/inputloder.gif";
import ConnectedBankOwnerCard from "../../components/Accounts/OwnerAccounts/ConnectedBankOwnerCard";
import MainDashboardAccountList from "../../components/Accounts/OwnerAccounts/MainDashboardAccountList";
import {
  ownerListViewAllAcc,
  resetpage,
  resetToggle,
  setshowsuccestoast,
} from "../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { toast, ToastContainer } from "react-toastify";
import { toast_position } from "../../redux/features/Settings/Authorization";
import { Tooltip, tooltipClasses } from "@mui/material";
import RefreshBalanceToast from "../../components/_utils/RefreshBalanceToast";
import xpentraLogom from "../../assets/newdashboardicon/xpentralogo_mobile.png";
import PopUpComponent from "../../components/_utils/pop_up/popUpComponent";
import ThreeUpcomingHolidays from "../../components/HomeDashboard/ThreeUpcomingHolidays";
import HolidaysSidebar from "../../components/HomeDashboard/HolidaysSidebar";

const Maindashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sidebarState, setSidebarState] = useState(false);
  const contents = ["Entity Details", "Upcoming Bank Holidays"];
  const contentsm = ["Transaction", "Account"];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentIndexm, setCurrentIndexm] = useState(0);
  const refreshData = useSelector((state) => state.refreshToken.data);
  const loading = useSelector((state) => state.homeDashboard.loading);
  const services = useSelector((state) => state.refreshToken.services);
  const [showSecondContent, setShowSecondContent] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [isFirstDivVisible, setIsFirstDivVisible] = useState(true);
  const [isFirstDivVisiblem, setIsFirstDivVisiblem] = useState(true);
  const [dashboardOwnersData, setDashboardOwnersData] = useState([]);
  const [connectedBankingTotal, setConnectedBankingTotal] = useState("");
  const [ownerAccounts, setOwnerAccounts] = useState([]);
  const [ownerDataLoading, setOwnerDataLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isBalanceSummaryOpen, setIsBalanceSummaryOpen] = useState(true);
  const [connectedBankingOwnerAccounts, setConnectedBankingOwnerAccounts] =
    useState([]);
  const { ownerAccBalanceLoading, showSuccessToast, ownerAccBalanceError } =
    useSelector((state) => state.ownerAllVerification);

  const { beneTxnViewAllAccData } = useSelector(
    (state) => state.beneTxnViewAccData
  );
  const contentRef = useRef(null);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.Height = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0px";
    }
  }, [isOpen]);

  const { beneViewAllAccData } = useSelector(
    (state) => state.beneAllVerification
  );

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClick = (e) => {
    e.stopPropagation();
    toggleSidebar();
  };

  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (id) => {
    setActiveTab(id);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let queryParams = {
          page_no: 1,
          page_item_count: 50,
        };

        dispatch(checkUsertoken());
        dispatch(beneTxnViewAllAcc(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {}, [beneTxnViewAllAccData]);

  useEffect(() => {
    async function fetchPendingAccountData() {
      try {
        let queryParams = {
          page_no: 1,
          page_size: 50,
          approval_status: ["pending", "approved"],
        };
        dispatch(checkUsertoken());
        dispatch(beneViewAllAcc(queryParams));
      } catch (error) {
        console.error(error);
      }
    }
    fetchPendingAccountData();
  }, []);

  useEffect(() => {}, [beneViewAllAccData]);

  useEffect(() => {
    dispatch(checkUsertoken());
    dispatch(getHolidays());
  }, []);

  const getMoreOwners = () => {
    setCurrentPage((prev) => prev + 1);
  };

  useEffect(() => {
    const getConnectedBankingData = async () => {
      if (currentPage === 1) {
        setOwnerDataLoading(true);
      }
      try {
        dispatch(checkUsertoken());
        const response = await dispatch(
          ownerListViewAllAcc({
            page: 1,
            page_size: 50,
            connected_banking: "Y",
          })
        );
        const newData = response?.payload?.owner_accounts || [];
        setConnectedBankingOwnerAccounts((prevData) =>
          currentPage === 1 ? newData : [...prevData, ...newData]
        );
        setConnectedBankingTotal(response?.payload?.total_balance);
        setOwnerAccounts(response?.payload?.owner_accounts);
        setOwnerDataLoading(false);
        setDashboardOwnersData(response?.payload);
      } catch (error) {
        console.error(error);
        setOwnerDataLoading(false);
      }
    };
    getConnectedBankingData();
  }, [dispatch, currentPage]);

  useEffect(() => {
    if (!ownerAccBalanceLoading) {
      setTimeout(() => {
        toast.dismiss();
        if (showSuccessToast) {
          setTimeout(() => {
            toast.success("Balance refreshed successfully", {
              position: "bottom-right",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            dispatch(setshowsuccestoast());
          }, 250);
        }
      }, 500);
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    if (ownerAccBalanceLoading) {
      RefreshBalanceToast();
    }
  }, [ownerAccBalanceLoading]);

  useEffect(() => {
    dispatch(setHideBreadCrumb({ hide: true }));
    return () => {
      dispatch(setHideBreadCrumb({ hide: false }));
    };
  }, []);

  const txnApprovalReq = useSelector(
    (state) => state.homeDashboard.txnApprovalReq
  );

  useEffect(() => {
    const adjustPadding = () => {
      const header = document.querySelector(".dashboard_header_div");
      const mainContent = document.querySelector(".dashboard_content_div");
      if (header && mainContent) {
        const headerHeight = header.offsetHeight;
        mainContent.style.paddingTop = `${headerHeight}px`;
      }
    };

    adjustPadding();
    window.addEventListener("resize", adjustPadding);

    return () => {
      window.removeEventListener("resize", adjustPadding);
    };
  }, []);

  const beneTxnNext = () => {
    setCurrentIndex((prevIndex) =>
      Math.min(prevIndex + 1, contents.length - 1)
    );
    setShowSecondContent(!showSecondContent);
  };

  const beneTxnPrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
    setShowSecondContent(!showSecondContent);
  };

  const beneTxnNextm = () => {
    setCurrentIndexm((prevIndex) =>
      Math.min(prevIndex + 1, contents.length - 1)
    );
    setShowSecondContent(!showSecondContent);
  };

  const beneTxnPrevm = () => {
    setCurrentIndexm((prevIndex) => Math.max(prevIndex - 1, 0));
    setShowSecondContent(!showSecondContent);
    setIsFirstDivVisiblem(true);
  };

  const [zIndex] = useState(1);

  const showToast = () => {
    toast.info("Feature coming soon!", toast_position);
  };

  const [showPopup, setShowPopup] = useState(false);
  return (
    <div>
      <div className="bg-custom-appBack flex flex-col h-screen">
        <div className="dashboard-container-div">
          <div id="webview" className="row">
            <div className="col-xl-8 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_welcome">
                <img
                  src={ProfileIcon}
                  alt=""
                  style={{ height: "50px", float: "left", margin: "10px" }}
                />
                <div className="dashboard_welcometext">
                  {refreshData?.employees ? (
                    <div style={{ margin: "10px" }}>
                      <span
                        className="font-semibold capitalize"
                        style={{ fontSize: "18px" }}
                      >
                        {`${refreshData.employees.first_name} ${refreshData.employees.last_name}`}
                      </span>
                      <br />
                      Welcome back
                    </div>
                  ) : null}
                </div>
                <br className="clearfix" />
              </div>
              <div className="dashboard_tranx">
                <div className="dashboard_tranxmain">
                  <div style={{ width: "100%" }}>
                    <div className="dashboardcontentleft">
                      <img
                        src={Transactionicon}
                        alt=""
                        style={{
                          width: "20px",
                          float: "left",
                          margin: "0px 10px 0px 5px",
                        }}
                      />
                      Transactions
                    </div>
                    <div
                      className="dashboardcontentright hover:bg-[#667CA3] hover:cursor-pointer"
                      onClick={() =>
                        navigate(
                          "/transaction/beneficiary_transaction/view_all",
                          {
                            state: { approvalStatus: "all" },
                          }
                        )
                      }
                    >
                      View All
                      <img
                        src={arrowright}
                        alt=""
                        style={{
                          width: "12px",
                          float: "right",
                          marginTop: "3px",
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="dashboard_tranxcontentmain">
                    <div className="tranxcontentmainfirst">
                      <div style={{ padding: "33px 20px" }}>
                        {beneTxnViewAllAccData && (
                          <div className="textcontent">
                            {(beneTxnViewAllAccData?.total_count &&
                              beneTxnViewAllAccData?.total_count?.toLocaleString()) ||
                              0}
                          </div>
                        )}
                        <div
                          className="textheader"
                          style={{ marginBottom: "20px" }}
                        >
                          Beneficiary transactions
                        </div>
                        <div className="textcontent">N/A</div>
                        <div className="textheader">Internal transactions</div>
                      </div>
                    </div>
                    <div style={{ display: "table-cell", width: "75%" }}>
                      <div
                        className="table-container"
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          height: "160px",
                        }}
                      >
                        <CSSTransition>
                          <div
                            className={`table-row ${
                              isFirstDivVisible
                                ? "slide-right-enter-done"
                                : "slide-right-exit-done"
                            }`}
                          >
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    //navigate("/create_transactions");
                                    //dispatch(setCurrentMenu("Transactions"));
                                    setIsFirstDivVisible(false);
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Pay"
                                      ? services?.find(
                                          (service) =>
                                            service === "CBeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={payicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">pay</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 20px" }}
                                  >
                                    Create an outgoing transaction
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "RCTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Receive"
                                      ? services?.find(
                                          (service) =>
                                            service === "RCTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "t"
                                        : ""
                                      : "",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "RCTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "RCTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={receiveicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Receive</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 14px" }}
                                  >
                                    Create an incoming transaction
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "ABeneficiaryTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/transaction/beneficiary_transaction/transaction_single_approval"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                    // setShowPopup(true);
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Approval required"
                                      ? services?.find(
                                          (service) =>
                                            service === "ABeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "ABeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                          ?.pending_transaction_count ===
                                          undefined ||
                                        txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0
                                        ? "0.5"
                                        : "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "ABeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0 ||
                                        undefined
                                        ? "not-allowed"
                                        : "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={approvalicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Approve</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Approve created transactions
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>
                        <CSSTransition>
                          <div
                            className={`table-row ${
                              !isFirstDivVisible
                                ? "slide-left-enter-done"
                                : "slide-left-exit-done"
                            }`}
                          >
                            {!loading && (
                              <div
                                className="tranxcontentmainsecond"
                                style={{
                                  position: "relative",
                                  cursor: "default",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    backgroundColor: "#314D7E",
                                    fontSize: "12px",
                                    padding: "10px",
                                    top: "0px",
                                    cursor: "pointer",
                                    borderBottomRightRadius: "20px",
                                  }}
                                  onClick={() => {
                                    setIsFirstDivVisible(true);
                                  }}
                                >
                                  Pay
                                  <img
                                    src={arrowleft}
                                    alt=""
                                    style={{
                                      width: "12px",
                                      float: "left",
                                      marginTop: "3px",
                                    }}
                                  />
                                </div>
                                <div className="borderright">
                                  <div
                                    className="textheader"
                                    style={{ padding: "47px 20px" }}
                                  >
                                    Select the transaction type you want to
                                    create
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthird"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/transaction/beneficiary_transaction/create"
                                    );
                                    dispatch(setCurrentMenu("Transactions"));
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Receive"
                                      ? services?.find(
                                          (service) =>
                                            service === "CBeneficiaryTxn" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "t"
                                        : ""
                                      : "",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "CBeneficiaryTxn" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div className="borderright">
                                  <div>
                                    <img
                                      src={beneficiaryicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Beneficiary</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Select category and pay a beneficiary
                                  </div>
                                </div>
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainforth"
                                onClick={() => showToast()}
                              >
                                <div>
                                  <div>
                                    <img
                                      src={internalicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "0px auto 10px auto",
                                      }}
                                    />
                                  </div>
                                  <div className="textcontent">Internal</div>
                                  <div
                                    className="textheader"
                                    style={{ padding: "0px 15px" }}
                                  >
                                    Transfer funds from one owner account to
                                    another
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </CSSTransition>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="dashboard_tranx">
                <div className="dashboard_tranxmain">
                  <div className="dashboardcontentleft">
                    <img
                      src={Accounticon}
                      alt=""
                      style={{
                        width: "20px",
                        float: "left",
                        margin: "0px 10px 0px 5px",
                      }}
                    />
                    Beneficiary Accounts
                  </div>
                  <div
                    className="dashboardcontentright hover:bg-[#667CA3] hover:cursor-pointer"
                    onClick={() =>
                      navigate("/accounts/beneficiary_account/view_all", {
                        state: { approvalStatus: "all" },
                      })
                    }
                  >
                    View All
                    <img
                      src={arrowright}
                      alt=""
                      style={{
                        width: "12px",
                        float: "right",
                        marginTop: "3px",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className="dashboard_tranxcontentmain">
                    <div className="tranxcontentmainfirst">
                      <div style={{ padding: "33px 20px" }}>
                        {beneViewAllAccData && (
                          <div className="textcontent">
                            {beneViewAllAccData?.summary
                              ?.pending_accounts_count ?? 0}
                          </div>
                        )}
                        <div
                          className="textheader"
                          style={{ marginBottom: "20px" }}
                        >
                          Pending accounts
                        </div>
                        {beneViewAllAccData && (
                          <div className="textcontent">
                            {beneViewAllAccData?.summary
                              ?.approved_accounts_count ?? 0}
                          </div>
                        )}
                        <div className="textheader">Approved accounts</div>
                      </div>
                    </div>
                    {!loading && (
                      <div
                        className="tranxcontentmainsecond"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) !== undefined
                          ) {
                            navigate("/accounts/beneficiary_account/create");
                            dispatch(setCurrentMenu("Accounts"));
                          }
                        }}
                        style={{
                          transition: "all 0.6s ease-in-out",
                          transform:
                            hover === "Pay"
                              ? services?.find(
                                  (service) =>
                                    service === "BUAccount" ||
                                    service === "Admin"
                                ) !== undefined
                                ? "translateY(-50px)"
                                : "translateY(0px)"
                              : "translateY(0px)",
                          opacity:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "0.5"
                              : "1",
                          cursor:
                            services?.find(
                              (service) =>
                                service === "BUAccount" || service === "Admin"
                            ) === undefined
                              ? "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div className="borderright">
                          <div>
                            <img
                              src={createicon}
                              alt=""
                              style={{
                                width: "60px",
                                margin: "0px auto 10px auto",
                              }}
                            />
                          </div>
                          <div className="textcontent">Create</div>
                          <div
                            className="textheader"
                            style={{ padding: "0px 20px" }}
                          >
                            Create a beneficiary account
                          </div>
                        </div>
                      </div>
                    )}
                    {!loading && (
                      <div
                        className="tranxcontentmainthird"
                        onClick={() => {
                          if (
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) !== undefined
                          ) {
                            navigate(
                              "/accounts/beneficiary_account/approval_required"
                            );
                            dispatch(setCurrentMenu("Accounts"));
                          }
                        }}
                        style={{
                          transition: "all 0.6s ease-in-out",
                          transform:
                            hover === "Approval required"
                              ? services?.find(
                                  (service) =>
                                    service === "BAAccount" ||
                                    service === "Admin"
                                ) !== undefined
                                ? "translateY(-50px)"
                                : "translateY(0px)"
                              : "translateY(0px)",
                          opacity:
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) === undefined
                              ? txnApprovalReq?.summary
                                  ?.pending_transaction_count === undefined ||
                                txnApprovalReq?.summary
                                  ?.pending_transaction_count === 0
                                ? "0.5"
                                : "0.5"
                              : "1",
                          cursor:
                            services?.find(
                              (service) =>
                                service === "BAAccount" || service === "Admin"
                            ) === undefined
                              ? txnApprovalReq?.summary
                                  ?.pending_transaction_count === 0 || undefined
                                ? "not-allowed"
                                : "not-allowed"
                              : "pointer",
                        }}
                      >
                        <div className="borderright">
                          <div>
                            <img
                              src={approvalicon}
                              alt=""
                              style={{
                                width: "60px",
                                margin: "5px auto 5px auto",
                              }}
                            />
                          </div>
                          <div className="textcontent">Approve</div>
                          <div
                            className="textheader"
                            style={{ padding: "0px 20px" }}
                          >
                            Approve created accounts
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="tranxcontentmainforth"
                      style={{ cursor: "default", opacity: "0.5" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_summery">
                <div className="dashboard_hedder">
                  <div className="Caraouseltab">
                    <div
                      className="CarouselDottabl"
                      onClick={beneTxnPrev}
                      disabled={currentIndex === 0}
                    >
                      <div style={{ width: "max-content", margin: "0px auto" }}>
                        <img
                          src={entityIcon}
                          alt=""
                          style={{
                            float: "left",
                            margin: "0px 5px",
                            width: "15px",
                          }}
                        />
                        {contents[0]}
                      </div>
                    </div>
                    <div
                      className="CarouselDottabr"
                      onClick={beneTxnNext}
                      disabled={currentIndex === 1}
                    >
                      <div style={{ width: "max-content", margin: "0px auto" }}>
                        <img
                          src={dateIcon}
                          alt=""
                          style={{
                            float: "left",
                            margin: "0px 5px",
                            width: "15px",
                          }}
                        />
                        {contents[1]}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="containerscroll">
                  <div
                    className={`${
                      !showSecondContent
                        ? "showFirstContentd"
                        : "hideFirstContentd"
                    }`}
                  >
                    {currentIndex === 0 && (
                      <>
                        <div className="dashboard_contenthedder">
                          <div className="textcontent">
                            <Tooltip
                              componentsProps={{
                                popper: {
                                  sx: {
                                    [`& .${tooltipClasses.tooltip}`]: {
                                      backgroundColor: (theme) =>
                                        theme.palette.common.black,
                                    },
                                    [`& .${tooltipClasses.arrow}`]: {
                                      color: (theme) =>
                                        theme.palette.common.black,
                                    },
                                  },
                                },
                              }}
                              arrow
                              PopperProps={{
                                style: {
                                  maxWidth: "none",
                                },
                              }}
                              title={refreshData?.entity?.entity_name}
                              placement="bottom-start"
                            >
                              <div className="textcontent">
                                {refreshData?.entity ? (
                                  <span>
                                    {refreshData.entity.entity_name.toLowerCase()}
                                  </span>
                                ) : null}
                              </div>
                            </Tooltip>
                          </div>
                          <div className="textheader">Entity name</div>
                          <div
                            style={{
                              padding: "15px 0px 0px 0px",
                              fontSize: "14px",
                              color: "#4DDD37",
                            }}
                          >
                            ₹
                            {connectedBankingTotal !== undefined
                              ? formatTotalBalance(connectedBankingTotal).amount
                              : "00.00"}
                            {connectedBankingTotal !== undefined &&
                              formatTotalBalance(connectedBankingTotal)
                                .prefix == "crores"}
                            <span
                              style={{ fontSize: "12px", color: "#DADADA" }}
                            >
                              &nbsp;in&nbsp;
                            </span>
                            {ownerAccounts?.length}&nbsp;
                            <span
                              style={{ fontSize: "12px", color: "#DADADA" }}
                            >
                              {ownerAccounts?.length === 0 ||
                              ownerAccounts?.length === 1
                                ? "account"
                                : "accounts"}
                            </span>
                          </div>
                          <div
                            style={{
                              padding: "0px 0px 15px 0px",
                              fontSize: "12px",
                              color: "#DADADA",
                            }}
                          >
                            Total balance&nbsp;
                            {formatTotalBalance(connectedBankingTotal).prefix ==
                            "crores"
                              ? "in crores"
                              : ""}
                          </div>
                        </div>
                        <div className="Caraouselcontent">
                          <div className="Caraouselcontentleft">
                            Balances Summary
                          </div>
                          <div
                            className="Caraouselcontentright hover:bg-[#667CA3] hover:cursor-pointer"
                            // onClick={async () => {
                            //   dispatch(resetpage());
                            //   dispatch(resetToggle());
                            //   navigate("/accounts/owner/view_all");
                            // }}
                            onClick={() =>
                              navigate("/accounts/owner/view_all", {
                                state: { approvalStatus: "all" },
                              })
                            }
                          >
                            View All
                            <img
                              src={arrowright}
                              alt=""
                              style={{
                                width: "12px",
                                float: "right",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </div>
                        <div className="Caraouselcontentmain">
                          {ownerDataLoading ? (
                            <div className=" flex justify-center items-center">
                              <img
                                src={Loader}
                                alt="Loading"
                                style={{ width: "50px", height: "50px" }}
                              />
                            </div>
                          ) : (
                            <>
                              <InfiniteScroll
                                style={{ width: "inherit" }}
                                dataLength={ownerAccounts?.length || 0}
                                next={getMoreOwners}
                                hasMore={
                                  currentPage <
                                  Math.ceil(
                                    dashboardOwnersData?.total_count / 50
                                  )
                                }
                                loader={
                                  <div className="loader-container m-auto justify-center items-center flex">
                                    <img src={Loader} alt="" />
                                  </div>
                                }
                                scrollableTarget="infinityScrollOwnersView"
                                scrollThreshold={0.8}
                              >
                                {connectedBankingOwnerAccounts?.map((owner) => (
                                  <ConnectedBankOwnerCard
                                    key={owner?.id}
                                    owner={owner}
                                    ownerAccounts={ownerAccounts}
                                    setOwnerAccounts={setOwnerAccounts}
                                    ownerAccBalanceLoading={
                                      ownerAccBalanceLoading
                                    }
                                    ownerAccBalanceError={ownerAccBalanceError}
                                    updateTotalBalance={
                                      setConnectedBankingTotal
                                    }
                                    total_balance={connectedBankingTotal}
                                    isBalanceSummaryOpen={isBalanceSummaryOpen}
                                    connectedBankingOwnerAccounts={
                                      connectedBankingOwnerAccounts
                                    }
                                    setConnectedBankingOwnerAccounts={
                                      setConnectedBankingOwnerAccounts
                                    }
                                  />
                                ))}
                              </InfiniteScroll>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={`${
                      showSecondContent
                        ? "showSecondContentd"
                        : "hideSecondContentd"
                    }`}
                  >
                    {currentIndex === 1 && (
                      <>
                        <div className="dashboard_contenthedder"></div>
                        <div
                          className="Caraouseldatemain"
                          style={{ padding: "0px" }}
                        >
                          <HolidaysSidebarnew />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="mobileview" className="row bodymaindiv">
            <div className="col-xl-8 col-md-12 col-sm-12 col-xs-12">
              <div className="dashboard_header_div" style={{ zIndex: zIndex }}>
                <div className="dashboard_logo_div">
                  <img
                    className="cursor-pointer"
                    width="150px"
                    src={xpentraLogom}
                    alt="xpentraLogo"
                  />
                </div>
                <div className="dashboard_header_main">
                  <div className="dashboard_header_left">
                    <div>{contents[0]}</div>
                    <div style={{ marginTop: "20px" }}>
                      <div className="textcontent">
                        <Tooltip
                          componentsProps={{
                            popper: {
                              sx: {
                                [`& .${tooltipClasses.tooltip}`]: {
                                  backgroundColor: (theme) =>
                                    theme.palette.common.black,
                                },
                                [`& .${tooltipClasses.arrow}`]: {
                                  color: (theme) => theme.palette.common.black,
                                },
                              },
                            },
                          }}
                          arrow
                          PopperProps={{
                            style: {
                              maxWidth: "none",
                            },
                          }}
                          title={refreshData?.entity?.entity_name}
                          placement="bottom-start"
                        >
                          <span>{refreshData?.entity?.entity_name}</span>
                        </Tooltip>
                        NA
                      </div>
                      <div className="textheader">Entity name</div>
                      <div
                        style={{
                          padding: "15px 0px 0px 0px",
                          fontSize: "14px",
                          color: "#4DDD37",
                        }}
                      >
                        ₹
                        {connectedBankingTotal !== undefined
                          ? formatTotalBalance(connectedBankingTotal).amount
                          : "00.00"}
                        {connectedBankingTotal !== undefined &&
                          formatTotalBalance(connectedBankingTotal).prefix ==
                            "crores"}
                        <span style={{ fontSize: "12px", color: "#DADADA" }}>
                          &nbsp;in&nbsp;
                        </span>
                        {ownerAccounts?.length}&nbsp;
                        <span style={{ fontSize: "12px", color: "#DADADA" }}>
                          {ownerAccounts?.length === 0 ||
                          ownerAccounts?.length === 1
                            ? "account"
                            : "accounts"}
                        </span>
                      </div>
                      <div
                        style={{
                          padding: "0px 0px 15px 0px",
                          fontSize: "12px",
                          color: "#DADADA",
                        }}
                      >
                        Total balance&nbsp;
                        {formatTotalBalance(connectedBankingTotal).prefix ==
                        "crores"
                          ? "in crores"
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="dashboard_header_right"></div>
                  <br className="clearfix" />
                </div>
                <div className="accordion">
                  <div className="accordion-title" onClick={toggleAccordion}>
                    <span>Balance Summary</span>
                    <div
                      className={`accordion-arrow ${isOpen ? "open" : ""}`}
                    />
                  </div>
                  <div
                    ref={contentRef}
                    className={`accordion-content ${isOpen ? "open" : ""}`}
                  >
                    <div>
                      <div className="Caraouselcontentmainm">
                        {ownerDataLoading ? (
                          <div className=" flex justify-center items-center">
                            <img
                              src={Loader}
                              alt="Loading"
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                        ) : (
                          <>
                            <InfiniteScroll
                              style={{ width: "inherit" }}
                              dataLength={ownerAccounts?.length || 0}
                              next={getMoreOwners}
                              hasMore={
                                currentPage <
                                Math.ceil(dashboardOwnersData?.total_count / 50)
                              }
                              loader={
                                <div className="loader-container m-auto justify-center items-center flex">
                                  <img src={Loader} alt="" />
                                </div>
                              }
                              scrollableTarget="infinityScrollOwnersView"
                              scrollThreshold={0.8}
                            >
                              {connectedBankingOwnerAccounts?.map((owner) => (
                                <MainDashboardAccountList
                                  key={owner?.id}
                                  owner={owner}
                                  ownerAccounts={ownerAccounts}
                                  setOwnerAccounts={setOwnerAccounts}
                                  ownerAccBalanceLoading={
                                    ownerAccBalanceLoading
                                  }
                                  ownerAccBalanceError={ownerAccBalanceError}
                                  updateTotalBalance={setConnectedBankingTotal}
                                  bankName={owner?.ifsc_code}
                                  total_balance={connectedBankingTotal}
                                  isBalanceSummaryOpen={isBalanceSummaryOpen}
                                  connectedBankingOwnerAccounts={
                                    connectedBankingOwnerAccounts
                                  }
                                  setConnectedBankingOwnerAccounts={
                                    setConnectedBankingOwnerAccounts
                                  }
                                  lastFetchedAt={
                                    owner?.owner_account_balance_last_fetched_at_in_words
                                  }
                                />
                              ))}
                            </InfiniteScroll>
                          </>
                        )}
                      </div>
                    </div>
                    {isOpen && (
                      <div
                        onClick={() =>
                          navigate("/accounts/owner/view_all", {
                            state: { approvalStatus: "all" },
                          })
                        }
                        style={{
                          position: "fixed",
                          width: "100%",
                          textAlign: "center",
                          bottom: "0px",
                          backgroundColor: "#5FA564",
                          padding: "20px",
                        }}
                      >
                        View All
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="dashboard_content_div"
                style={{ marginTop: "35px" }}
              >
                <div className="dashboard_summerym">
                  <div className="dashboard_hedderm">
                    <div className="Caraouseltab">
                      <div
                        className={`CarouselDottablm ${
                          currentIndexm === 0 ? "activetab" : ""
                        }`}
                        disabled={currentIndexm === 0 && isFirstDivVisiblem}
                        onClick={() => {
                          if (currentIndexm === 1 && isFirstDivVisiblem) {
                            beneTxnPrevm();
                          } else if (
                            currentIndexm === 1 &&
                            !isFirstDivVisiblem
                          ) {
                            beneTxnPrevm();
                            setIsFirstDivVisiblem(false);
                          } else {
                            setIsFirstDivVisiblem(true);
                          }
                        }}
                      >
                        <div
                          style={{ width: "max-content", margin: "0px auto" }}
                        >
                          {isFirstDivVisiblem && (
                            <div style={{ cursor: "pointer" }}>
                              <img
                                src={Transactionicon}
                                alt=""
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              {contentsm[0]}
                            </div>
                          )}
                          {!isFirstDivVisiblem && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setIsFirstDivVisiblem(true);
                              }}
                            >
                              <img
                                src={arrowleft}
                                alt=""
                                style={{
                                  float: "left",
                                  margin: "0px 5px",
                                  width: "15px",
                                }}
                              />
                              Pay
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="CarouselDottabrm"
                        onClick={beneTxnNextm}
                        disabled={currentIndexm === 1}
                      >
                        <div
                          style={{ width: "max-content", margin: "0px auto" }}
                        >
                          <img
                            src={Accounticon}
                            alt=""
                            style={{
                              float: "left",
                              margin: "0px 5px",
                              width: "15px",
                            }}
                          />
                          {contentsm[1]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="containerscrollm">
                    <div
                      className={`${
                        !showSecondContent
                          ? "showFirstContentdm"
                          : "hideFirstContentdm"
                      }`}
                    >
                      {currentIndexm === 0 && (
                        <>
                          <div className="dashboard_tranxcontentmainmm">
                            <div className="tranxcontentmainfirstm">
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "left",
                                }}
                              >
                                {beneTxnViewAllAccData && (
                                  <div className="textcontent">
                                    {(beneTxnViewAllAccData?.total_count &&
                                      beneTxnViewAllAccData?.total_count?.toLocaleString()) ||
                                      0}
                                  </div>
                                )}
                                <div className="textheader">
                                  Beneficiary transactions
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "right",
                                }}
                              >
                                <div className="textcontent">N/A</div>
                                <div className="textheader">
                                  Internal transactions
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div>
                            <div
                              className="table-containerm"
                              style={{
                                position: "relative",
                                overflow: "hidden",
                              }}
                            >
                              <CSSTransition>
                                <div
                                  className={`table-rowm ${
                                    isFirstDivVisiblem
                                      ? "slide-right-enter-done"
                                      : "slide-right-exit-done"
                                  }`}
                                >
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainsecondm"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn" ||
                                              service === "Admin"
                                          ) !== undefined
                                        ) {
                                          setIsFirstDivVisiblem(false);
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Pay"
                                            ? services?.find(
                                                (service) =>
                                                  service ===
                                                    "CBeneficiaryTxn" ||
                                                  service === "Admin"
                                              ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={payicon}
                                            alt=""
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">pay</div>
                                        <div className="textheader">
                                          Create an outgoing transaction
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainthirdm"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "RCTxn" ||
                                              service === "Admin"
                                          ) !== undefined
                                        ) {
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                                (service) =>
                                                  service === "RCTxn" ||
                                                  service === "Admin"
                                              ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "RCTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "RCTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={receiveicon}
                                            alt=""
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Receive
                                        </div>
                                        <div className="textheader">
                                          Create an incoming transaction
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainforthm"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn" ||
                                              service === "Admin"
                                          ) !== undefined
                                        ) {
                                          navigate(
                                            "/transaction/beneficiary_transaction/transaction_single_approval"
                                          );
                                          dispatch(
                                            setCurrentMenu("Transactions")
                                          );
                                          // setShowPopup(true);
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                                (service) =>
                                                  service ===
                                                    "ABeneficiaryTxn" ||
                                                  service === "Admin"
                                              ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "ABeneficiaryTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={approvalicon}
                                            alt=""
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Approve
                                        </div>
                                        <div className="textheader">
                                          Approve created transactions
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                </div>
                              </CSSTransition>
                              <CSSTransition>
                                <div
                                  className={`table-row ${
                                    !isFirstDivVisiblem
                                      ? "slide-left-enter-done"
                                      : "slide-left-exit-done"
                                  }`}
                                >
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainthirdm"
                                      onClick={() => {
                                        if (
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn" ||
                                              service === "Admin"
                                          ) !== undefined
                                        ) {
                                          navigate(
                                            "/transaction/beneficiary_transaction/create"
                                          );
                                          dispatch(
                                            setCurrentMenu("Transactions")
                                          );
                                        }
                                      }}
                                      style={{
                                        transition: "all 0.6s ease-in-out",
                                        transform:
                                          hover === "Approval required"
                                            ? services?.find(
                                                (service) =>
                                                  service ===
                                                    "CBeneficiaryTxn" ||
                                                  service === "Admin"
                                              ) !== undefined
                                              ? "translateY(-50px)"
                                              : "translateY(0px)"
                                            : "translateY(0px)",
                                        opacity:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                undefined ||
                                              txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                0
                                              ? "0.5"
                                              : "0.5"
                                            : "1",
                                        cursor:
                                          services?.find(
                                            (service) =>
                                              service === "CBeneficiaryTxn" ||
                                              service === "Admin"
                                          ) === undefined
                                            ? txnApprovalReq?.summary
                                                ?.pending_transaction_count ===
                                                0 || undefined
                                              ? "not-allowed"
                                              : "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={beneficiaryicon}
                                            alt=""
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Beneficiary
                                        </div>
                                        <div className="textheader">
                                          Select category and pay a beneficiary
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                    </div>
                                  )}
                                  {!loading && (
                                    <div
                                      className="tranxcontentmainforthm"
                                      onClick={() => showToast()}
                                    >
                                      <div
                                        style={{ width: "15%", float: "left" }}
                                      >
                                        <div>
                                          <img
                                            src={internalicon}
                                            alt=""
                                            style={{
                                              width: "60px",
                                              margin: "5px auto 5px auto",
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          width: "85%",
                                          float: "left",
                                          padding: "5px 10px",
                                        }}
                                      >
                                        <div className="textcontent">
                                          Internal
                                        </div>
                                        <div className="textheader">
                                          Transfer funds from one owner account
                                          to another
                                        </div>
                                      </div>
                                      <br className="clearfix" />
                                      <ToastContainer />
                                    </div>
                                  )}
                                </div>
                              </CSSTransition>
                            </div>
                          </div>
                        </>
                      )}
                    </div>

                    <div
                      className={`${
                        showSecondContent
                          ? "showSecondContentdm"
                          : "hideSecondContentdm"
                      }`}
                    >
                      {currentIndexm === 1 && (
                        <>
                          <div className="dashboard_tranxcontentmainmm">
                            <div className="tranxcontentmainfirstm">
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "left",
                                }}
                              >
                                {beneViewAllAccData && (
                                  <div className="textcontent">
                                    {beneViewAllAccData?.summary
                                      ?.pending_accounts_count ?? 0}
                                  </div>
                                )}
                                <div className="textheader">
                                  Pending accounts
                                </div>
                              </div>
                              <div
                                style={{
                                  width: "50%",
                                  float: "left",
                                  textAlign: "right",
                                }}
                              >
                                {beneViewAllAccData && (
                                  <div className="textcontent">
                                    {beneViewAllAccData?.summary
                                      ?.approved_accounts_count ?? 0}
                                  </div>
                                )}
                                <div className="textheader">
                                  Approved accounts
                                </div>
                              </div>
                              <br className="clearfix" />
                            </div>
                            {!loading && (
                              <div
                                className="tranxcontentmainsecondm"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "BUAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/accounts/beneficiary_account/create"
                                    );
                                    dispatch(setCurrentMenu("Accounts"));
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Pay"
                                      ? services?.find(
                                          (service) =>
                                            service === "BUAccount" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "BUAccount" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "BUAccount" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div style={{ width: "15%", float: "left" }}>
                                  <div>
                                    <img
                                      src={createicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "5px auto 5px auto",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "85%",
                                    float: "left",
                                    padding: "5px 10px",
                                  }}
                                >
                                  <div className="textcontent">Create</div>
                                  <div className="textheader">
                                    Create a beneficiary account
                                  </div>
                                </div>
                                <br className="clearfix" />
                              </div>
                            )}
                            {!loading && (
                              <div
                                className="tranxcontentmainthirdm"
                                onClick={() => {
                                  if (
                                    services?.find(
                                      (service) =>
                                        service === "BAAccount" ||
                                        service === "Admin"
                                    ) !== undefined
                                  ) {
                                    navigate(
                                      "/accounts/beneficiary_account/approval_required"
                                    );
                                    dispatch(setCurrentMenu("Accounts"));
                                  }
                                }}
                                style={{
                                  transition: "all 0.6s ease-in-out",
                                  transform:
                                    hover === "Approval required"
                                      ? services?.find(
                                          (service) =>
                                            service === "BAAccount" ||
                                            service === "Admin"
                                        ) !== undefined
                                        ? "translateY(-50px)"
                                        : "translateY(0px)"
                                      : "translateY(0px)",
                                  opacity:
                                    services?.find(
                                      (service) =>
                                        service === "BAAccount" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                          ?.pending_transaction_count ===
                                          undefined ||
                                        txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0
                                        ? "0.5"
                                        : "0.5"
                                      : "1",
                                  cursor:
                                    services?.find(
                                      (service) =>
                                        service === "BAAccount" ||
                                        service === "Admin"
                                    ) === undefined
                                      ? txnApprovalReq?.summary
                                          ?.pending_transaction_count === 0 ||
                                        undefined
                                        ? "not-allowed"
                                        : "not-allowed"
                                      : "pointer",
                                }}
                              >
                                <div style={{ width: "15%", float: "left" }}>
                                  <div>
                                    <img
                                      src={approvalicon}
                                      alt=""
                                      style={{
                                        width: "60px",
                                        margin: "5px auto 5px auto",
                                      }}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "85%",
                                    float: "left",
                                    padding: "5px 10px",
                                  }}
                                >
                                  <div className="textcontent">Approve</div>
                                  <div className="textheader">
                                    Approve created accounts
                                  </div>
                                </div>
                                <br className="clearfix" />
                              </div>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <br className="clearfix" />
                </div>
              </div>
              <div>
                <ThreeUpcomingHolidays setSidebarState={setSidebarState} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showPopup && <PopUpComponent setShowPopup={setShowPopup} />}
      <HolidaysSidebar state={sidebarState} setState={setSidebarState} />
    </div>
  );
};

export default Maindashboard;
