import React, { useEffect, useState } from "react";
import "./RequireApproval.css";
import { useDispatch, useSelector } from "react-redux";
import xpentraloader from "../../../../assets/Beneficiary Icons/lodemore.gif";
import { toast } from "react-toastify";
import { viewAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";
import { beneDeniedAccount } from "../../../../redux/features/beneficiary/beneAllVerification/beneAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import {
  beneTxnDeniedApproval,
  beneTxnMultiDeniedApproval,
  beneTxnViewAllAcc,
} from "../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import { useNavigate } from "react-router-dom";
import RefreshToast from "../../../../components/_utils/RefreshToast";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { resetBeneTxnMultiApprovalError } from "../../../../redux/features/beneficiary/beneTxnViewAccData/beneTxnViewAccData";
import RejectingTransactionPopup from "./RejectingTransactionPopup/RejectingTransactionPopup";
import { achivedAccountDetail } from "../../../../redux/features/verifyPAN/verifyPAN";

const DeclinedPopup = ({
  onClose,
  accountId,
  allAccounts,
  updateAllAccounts,
  type,
  status,
  sideDrawer,
  ShowAccountName,
  pageName,
  showRejectTnxPopup,
  setRejectTxnPopup,
  archive_id,
}) => {
  const [declineReason, setDeclineReason] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showTransitionClass, setShowTransitionClass] = useState("");
  const [cancleButtonHide, SetCancleButtonHide] = useState(true);
  const [inputField, SetinputField] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(false);

  const { viewAccountDetailData } = useSelector((state) => state.verify);
  const { BeneTxnDeniedApprovalLoading, BeneTxnMultiDeniedApprovalLoading } =
    useSelector((state) => state.beneTxnViewAccData);
  const { beneDeniedApprovalLoading } = useSelector(
    (state) => state.beneAllVerification
  );
  const {
    beneTxnViewAllAccLoading,
    beneTxnViewAllAccData,
    beneTxnViewAllAccError,
  } = useSelector((state) => state?.beneTxnViewAccData);

  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const handleInputChange = (event) => {
    setDeclineReason(event.target.value);

    const isEmpty = event.target.value.trim() === "";

    const inputField = document.querySelector(".Beneinputfield input");
    if (inputField) {
      if (!isEmpty && event.target.value.replace(/\s+/g, "").length >= 10) {
        inputField.style.border = "1px solid #4DDD37";
      } else {
        inputField.style.border = "1px solid rgb(0, 255, 245)";
      }
    }

    const confirmButton = document.querySelector(".confirmBtn");
    if (confirmButton) {
      if (!isEmpty && event.target.value.replace(/\s+/g, "").length >= 10) {
        confirmButton.style.width = "100px";
        confirmButton.style.height = "42px";
        confirmButton.style.background = "#3CB371";
        confirmButton.style.boxShadow = "0px 3px 6px #00000029";
        confirmButton.style.borderRadius = "20px";
        confirmButton.style.border = "none";
        confirmButton.style.opacity = "1";
        confirmButton.style.color = "#FFFFFF";
        confirmButton.style.cursor = "pointer";
      } else {
        confirmButton.style.width = "100px";
        confirmButton.style.height = "42px";
        confirmButton.style.background = "";
        confirmButton.style.boxShadow = "0px 3px 6px #00000029";
        confirmButton.style.border = "2px solid #CCCCCC";
        confirmButton.style.borderRadius = "20px";
        confirmButton.style.opacity = "0.55";
        confirmButton.style.cursor = "initial";
      }
    }
  };

  const handleBlur = () => {
    if (declineReason?.length === 0) {
      setIsFocus(false);
    }
  };
  useEffect(() => {
    if (beneDeniedApprovalLoading) {
      RefreshToast("accountsRefresh");
    } else if (
      BeneTxnDeniedApprovalLoading ||
      BeneTxnMultiDeniedApprovalLoading
    ) {
      RefreshToast();
    }
    if (
      !BeneTxnDeniedApprovalLoading ||
      BeneTxnMultiDeniedApprovalLoading ||
      beneDeniedApprovalLoading
    ) {
      setTimeout(() => {
        toast.dismiss();
      }, 2000);
    }
  }, [
    BeneTxnDeniedApprovalLoading,
    BeneTxnMultiDeniedApprovalLoading,
    beneDeniedApprovalLoading,
  ]);

  const handleFocus = () => {
    setIsFocus(true);
  };

  const handleRejectTransactionChecker = async (id, AccountIdData) => {
    setShowLoader(true);
    let queryParams = {
      page_no: 1,
      page_item_count: 50,
      lifecycle_status: "pending",
      beneficiary_account_id: id,
    };
    dispatch(checkUsertoken());
    const response = await dispatch(beneTxnViewAllAcc(queryParams));
    const data = response?.payload?.transactions;

    if (data?.length > 0 && response?.payload?.status) {
      setRejectTxnPopup(true);
      setShowLoader(false);
    } else {
      handleConfirm(id, AccountIdData);
    }
  };

  const handleConfirm = async (id, AccountIdData) => {
    setShowLoader(true);
    setInputDisabled(true);
    dispatch(checkUsertoken());
    const data = await dispatch(beneDeniedAccount({ id, AccountIdData }));

    SetinputField(false);

    setShowLoader(true);
    SetCancleButtonHide(false);
    await new Promise((resolve) => {
      setTimeout(resolve, 2000);
    });

    if (type === "decline account details") {
      if (data?.payload?.status === false || data?.payload === undefined) {
        // const error_msg = data?.payload?.errors?.beneficiary_account[0];
        let error_msg = "";
        let error_list = null;
        if (data?.payload === undefined) {
          error_msg = "An error occured while taking action on this account";
        } else if (data?.payload?.errors?.beneficiary_account) {
          if (data?.payload?.errors?.beneficiary_account?.length > 1) {
            error_list = data?.payload?.errors?.beneficiary_account;
            error_msg = data?.payload?.errors?.beneficiary_account[0];
          } else {
            error_msg = data?.payload?.errors?.beneficiary_account;
            toast.error(error_msg[0], {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              progress: undefined,
              theme: "colored",
            });
          }
        } else if (data?.payload?.errors?.bank_account_number?.[0]) {
          error_msg = data?.payload?.errors?.bank_account_number[0];
        }

        if (error_msg === "Beneficiary account already rejected") {
          dispatch(checkUsertoken());
          const newData = await dispatch(viewAccountDetail({ id }));
          const updatedBeneficiaryAccount = {
            ...newData?.payload?.beneficiary_accounts?.[0],
            error_msg,
            error: data?.payload?.status === false ? true : false,
          };

          const updatedData = allAccounts.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                ...(updatedBeneficiaryAccount || {}),
              };
            }
            return item;
          });
          updateAllAccounts(updatedData);
        } else {
          const updatedData = allAccounts.map((item) => {
            if (item.id === id) {
              return {
                ...item,
                error_msg,
                error: data?.payload?.status === false ? true : false,
              };
            }
            return item;
          });
          updateAllAccounts(updatedData);
        }
        setShowLoader(false);
        setInputDisabled(false);
        setDeclineReason("");
        onClose();
        if (Array.isArray(error_list) && error_list.length > 1) {
          error_list.forEach((err) => {
            setTimeout(() => {
              toast.error(err, toast_position);
            }, 500);
          });
        } else {
          toast.error(error_msg, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      } else {
        const updatedData = allAccounts.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                item.approval_status === "pending" ? "rejected" : "pending",

              rejected_on:
                data?.payload?.status === true
                  ? data?.payload?.beneficiary_accounts[0]?.rejected_on
                  : "",
              rejected_by_name:
                data?.payload?.status === true
                  ? data?.payload?.beneficiary_accounts[0]?.rejected_by_name
                  : "",
              rejection_reason: AccountIdData,
              error:
                item.error === false && data?.payload?.status === false
                  ? true
                  : false,
              message:
                item.message === "" && data?.payload?.status === false
                  ? data?.payload?.errors?.beneficiary_account[0]
                  : "",
            };
          }
          return item;
        });

        updateAllAccounts(updatedData);
        setShowLoader(false);
        setInputDisabled(false);
        setDeclineReason("");
        onClose();

        // if (data?.payload.status === true) {
        toast.success("Account Rejected successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        // }
        if (pageName == "Success page decline account") {
          sessionStorage.setItem(
            "Create BeneAcc Approve and Decline status",
            true
          );
          Navigate(`/accounts/beneficiary_account/details/${id}`);
        }
      }
    } else {
      if (data?.payload?.status === false) {
        let error_msg = "";
        if (data?.payload?.errors?.beneficiary_account?.[0]) {
          error_msg = data?.payload?.errors?.beneficiary_account[0];
        } else if (data?.payload?.errors?.bank_account_number?.[0]) {
          error_msg = data?.payload?.errors?.bank_account_number[0];
        } else {
          error_msg = "An unknown error occurred.";
        }
        const updatedData = allAccounts?.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                item.approval_status === "pending" ? "error" : "pending",

              rejected_on:
                data.payload.status === true
                  ? data?.payload?.beneficiary_accounts[0]?.rejected_on
                  : "",
              rejected_by_name:
                data.payload.status === true
                  ? data?.payload?.beneficiary_accounts[0]?.rejected_by_name
                  : "",
              rejection_reason: AccountIdData,
              error:
                item.error === "false" && data?.payload?.status === false
                  ? "true"
                  : "false",
              message: data?.payload?.status === false ? error_msg : "",
            };
          }
          return item;
        });

        updateAllAccounts(updatedData);

        setShowLoader(false);
        setInputDisabled(false);
        setDeclineReason("");
        onClose();
        toast.error(error_msg, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
        // }
      } else {
        const updatedData = allAccounts.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              approval_status:
                item.approval_status === "pending" ? "rejected" : "pending",

              rejected_on:
                data.payload.status === true
                  ? data?.payload?.beneficiary_accounts[0]?.rejected_on
                  : "",
              rejected_by_name:
                data.payload.status === true
                  ? data?.payload?.beneficiary_accounts[0]?.rejected_by_name
                  : "",
              rejection_reason: AccountIdData,
              error:
                item.error === "false" && data?.payload?.status === false
                  ? "true"
                  : "false",
              message:
                item.message === "" && data?.payload?.status === false
                  ? data?.payload?.errors?.beneficiary_account[0]
                  : "",
            };
          }
          return item;
        });

        updateAllAccounts(updatedData);
        setShowLoader(false);
        setInputDisabled(false);
        setDeclineReason("");
        onClose();
        if (data?.payload?.status === true) {
          toast.success("Account Rejected successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    }
  };

  const BeneTxnDeclinedPopUp = async (id, AccountIdData) => {
    setShowLoader(true);
    setInputDisabled(true);

    if (sideDrawer === "SideDrawer") {
      dispatch(checkUsertoken());
      const data = await dispatch(beneDeniedAccount({ id, AccountIdData }));
      let result5 = "";
      let result6 = "";
      let result7 = "";
      let totalData1 = "";

      await new Promise((resolve) => setTimeout(resolve, 2000));
      if (data) {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              let lifecycleStatus = "";

              if (data.payload.status === true) {
                lifecycleStatus = "rejected";
              }
              if (data.payload.status === false) {
                lifecycleStatus = "error";
              }

              return {
                ...item,
                approval_status:
                  item.approval_status === "pending"
                    ? lifecycleStatus
                    : "pending",
                status: data?.payload?.status,
                message:
                  data?.payload?.status === false
                    ? data?.payload?.errors?.beneficiary_account?.[0]
                    : "",
              };
            }
            return item;
          }
        );

        if (data?.payload.status === false) {
          if (data?.payload.status === false) {
            if (
              data?.payload?.errors?.bank_account_number?.[0] &&
              data?.payload?.errors?.permanent_account_number?.[0] &&
              data?.payload?.errors?.beneficiary_account?.[0]
            ) {
              result5 = data?.payload?.errors?.bank_account_number[0];
              result6 = data?.payload?.errors.permanent_account_number[0];
              result7 = data.payload.errors.beneficiary_account[0];
              totalData1 = [result5, result6, result7];

              totalData1.forEach((error5) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error5, toast_position);
                  }, 800);
                }
              });
            } else if (data?.payload?.errors?.beneficiary_account.length >= 0) {
              const res = data.payload.errors.beneficiary_account;
              res.forEach((err8) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(err8, toast_position);
                  }, 1000);
                }
              });
            }
          }
        } else {
          toast.success("Transaction Rejected successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
        updateAllAccounts(updatedData[0]);
      }
    } else if (sideDrawer === "multiApproval") {
      dispatch(checkUsertoken());
      const data = await dispatch(
        beneTxnMultiDeniedApproval({ id, AccountIdData })
      );
      dispatch(resetBeneTxnMultiApprovalError());
      await new Promise((resolve) => setTimeout(resolve, 2000));
      SetCancleButtonHide(false);

      if (data?.payload?.status === false) {
        const error_msg = data?.payload?.errors?.base[0]
          ? data?.payload?.errors?.base[0]
          : data?.payload?.errors?.base[0].errors[0];
        toast.error(error_msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });

        Navigate(
          "/transaction/Beneficiary_Transaction/multi_transaction_approval/error"
        );
      } else {
        Navigate(
          "/transaction/Beneficiary_Transaction/multi_transaction_approval/declined"
        );
      }
    } else {
      dispatch(checkUsertoken());
      const data = await dispatch(beneTxnDeniedApproval({ id, AccountIdData }));

      await new Promise((resolve) =>
        setTimeout(() => {
          resolve();
        }, 2000)
      );
      SetCancleButtonHide(false);
      const updatedData = allAccounts.map((item) => {
        if (item?.txn_id === id) {
          let lifecycleStatus = "";
          let errorMessage = "";

          if (
            data.payload.status === true &&
            data.payload.transactions[0].lifecycle_status === "rejected"
          ) {
            lifecycleStatus = data.payload.transactions[0].lifecycle_status;
          }

          if (
            data?.payload?.errors?.base[0]?.errors &&
            data?.payload?.errors?.base[0]?.errors[0]
          ) {
            errorMessage = data.payload.errors.base[0].errors[0];
            if (data?.payload?.errors?.base[0]?.errors?.length >= 1) {
              let msg3 = data.payload.errors.base[0].errors;
              msg3.forEach((error2) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error2, toast_position);
                  }, 1000);
                }
              });
            }
          } else if (data?.payload?.errors?.base[0]) {
            errorMessage = data.payload.errors.base[0];
          } else if (
            data?.payload?.status === true &&
            data?.payload?.transactions[0].lifecycle_status === "pending"
          ) {
            lifecycleStatus = "pendingOne";
          }

          return {
            ...item,
            lifecycle_status:
              data?.payload?.status === true &&
              item.lifecycle_status === "pending"
                ? lifecycleStatus
                : "error",
            rejected: true,
            rejection_reason: AccountIdData,
            error:
              item.error === "false" && data?.payload?.status === false
                ? "true"
                : "false",
            message:
              item.message === "" && data?.payload?.status === false
                ? errorMessage
                : "",
          };
        }
        return item;
      });

      updateAllAccounts(updatedData);

      if (data?.payload.status === false) {
        const error_msg = data?.payload?.errors?.base[0]
          ? data?.payload?.errors?.base[0]
          : data?.payload?.errors?.base[0].errors[0];
        toast.error(error_msg, {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.success("Transaction Rejected  successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    setShowTransitionClass("transition-effect");

    setShowLoader(false);
    setInputDisabled(false);
    setDeclineReason("");
    onClose();
  };

  const BeneTxnDeclinedDtls = async (id, AccountIdData) => {
    setShowLoader(true);
    setInputDisabled(true);
    if (sideDrawer === "SideDrawer") {
      dispatch(checkUsertoken());
      const data = await dispatch(beneDeniedAccount({ id, AccountIdData }));

      if (data) {
        const updatedData = viewAccountDetailData?.beneficiary_accounts.map(
          (item) => {
            if (item.id === id) {
              return {
                ...item,
                approval_status:
                  item.approval_status === "pending" ? "rejected" : "approved",
                status: data?.payload?.status,
                message:
                  data?.payload?.status === false
                    ? data?.payload?.errors?.beneficiary_account[0]
                    : "",
              };
            }
            return item;
          }
        );
        if (data?.payload.status === false) {
          toast.error(data?.payload?.errors?.beneficiary_account[0], {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.success("Transaction Rejected successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "colored",
          });
        }
        updateAllAccounts(updatedData[0]);
      }
    } else {
      dispatch(checkUsertoken());
      const data = await dispatch(beneTxnDeniedApproval({ id, AccountIdData }));
      await new Promise((resolve) => setTimeout(resolve, 2000));
      SetCancleButtonHide(false);
      const updatedData = allAccounts.map((item) => {
        if (item?.txn_id === id) {
          let lifecycleStatus = "";
          let errorMessage = "";

          if (
            data.payload.status === true &&
            data.payload.transactions[0].lifecycle_status === "rejected"
          ) {
            lifecycleStatus = data.payload.transactions[0].lifecycle_status;
          }
          if (
            data?.payload?.errors?.base[0]?.errors &&
            data?.payload?.errors?.base[0]?.errors[0]
          ) {
            errorMessage = data.payload.errors.base[0].errors[0];
            if (data?.payload?.errors?.base[0]?.errors.length >= 1) {
              let msg1 = data?.payload?.errors?.base[0]?.errors;
              msg1.forEach((error1) => {
                if (data?.payload?.status === false) {
                  setTimeout(() => {
                    toast.error(error1, toast_position);
                  }, 1000);
                }
              });
            }
          } else if (data?.payload?.errors?.base[0]) {
            errorMessage = data.payload.errors.base[0];
            toast.error(errorMessage, toast_position);
          } else if (
            data.payload.status === true &&
            data.payload.transactions[0].lifecycle_status === "pending"
          ) {
            lifecycleStatus = "pendingOne";
          }

          return {
            ...item,
            lifecycle_status:
              data?.payload.status === true &&
              item.lifecycle_status === "pending"
                ? lifecycleStatus
                : "pending",
            rejected: true,
            rejection_reason: AccountIdData,
            logs: data?.payload?.transactions?.[0]?.logs
              ? data?.payload?.transactions[0]?.logs
              : null,
            error:
              item.error === "false" && data?.payload?.status === false
                ? "true"
                : "false",
            message:
              item.message === "" && data?.payload?.status === false
                ? errorMessage
                : "",
          };
        }
        return item;
      });

      updateAllAccounts(updatedData);
      if (data?.payload?.status === false) {
        const error_msg = data?.payload?.errors?.base[0]
          ? data?.payload?.errors?.base[0]
          : data?.payload?.errors?.base[0].errors[0];
      } else {
        toast.success("Transaction Rejected successfully", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
        });
      }
    }

    setShowTransitionClass("transition-effect");

    setShowLoader(false);
    setInputDisabled(false);
    setDeclineReason("");
    onClose();
  };

  const handleRejectAllTransaction = async () => {
    setShowLoader(true);
    let AccountIdData = declineReason;

    let all_txn_id =
      !beneTxnViewAllAccLoading &&
      beneTxnViewAllAccData?.transactions?.length > 0
        ? beneTxnViewAllAccData?.transactions.map((data) => {
            return data?.txn_id;
          })
        : [];

    dispatch(checkUsertoken());
    const data = await dispatch(
      beneTxnMultiDeniedApproval({ id: all_txn_id, AccountIdData })
    );

    await new Promise((resolve) => setTimeout(resolve, 2000));

    if (data?.payload?.status === false) {
      const error_msg = data?.payload?.errors?.base[0].errors[0]
        ? data?.payload?.errors?.base[0].errors[0]
        : data?.payload?.errors?.base[0];
      toast.error(error_msg, {
        position: "bottom-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });
      setTimeout(() => {
        onClose();
      }, 500);
    } else if (data?.payload?.status) {
      toast.success("Transaction Rejected successfully", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
      });

      let thunkAction = achivedAccountDetail;
      dispatch(checkUsertoken());
      const archived_response = await dispatch(thunkAction({ id: archive_id }));
      if (
        archived_response.type !== "achivedAccountDetail/rejected" ||
        archived_response.payload === "Beneficiary account already archived"
      ) {
        dispatch(checkUsertoken());
        await dispatch(viewAccountDetail({ id: archive_id }));
      }

      setTimeout(() => {
        onClose();
      }, 500);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.key === "Enter" &&
        !inputDisabled &&
        declineReason.trim().length > 4
      ) {
        if (
          status === "Transaction" ||
          status === "MultiApprovalDeclinedTransaction"
        ) {
          BeneTxnDeclinedPopUp(accountId, declineReason);
        } else {
          handleConfirm(accountId, declineReason);
        }
      }
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [inputDisabled, declineReason, accountId, status]);
  return (
    <>
      {showRejectTnxPopup && (
        <div className="fixed top-0 left-0 w-screen h-screen flex items-center justify-center z-[9998] bg-[#000] bg-opacity-80">
          <RejectingTransactionPopup
            accountId={accountId}
            declineReason={declineReason}
            handleClosePopup={onClose}
            handleConfirm={handleConfirm}
            setRejectTxnPopup={setRejectTxnPopup}
          />
        </div>
      )}
      <div className="mainPopup">
        {status === "Transaction" ? (
          <div className="DeclinedAndpopup">
            <div className="declinedReasion">
              <h2>Rejected reason</h2>
              {ShowAccountName ? (
                <p>Please state the reason for rejecting the account</p>
              ) : (
                <p>Please state the reason for rejecting the transaction</p>
              )}
            </div>

            <div
              className={`Beneinputfield ${
                declineReason.length >= 9 ? "valid" : ""
              }`}
            >
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "1px solid #00FFF5"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                value={declineReason}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                for="myInput"
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>

            <div className="BeneTxn_benefbtnconfandCan">
              {showLoader ? (
                <button id="loader" className="BeneTxn_expentraLoaderclass">
                  <img src={xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <div className="flex items-center gap-[20px]">
                  {cancleButtonHide && (
                    <button
                      id="cancelBtn"
                      onClick={onClose}
                      className={`cancleBtn ${showTransitionClass}`}
                    >
                      Cancel
                    </button>
                  )}

                  <button
                    className={`confirmBtn ${showTransitionClass}`}
                    onClick={() =>
                      status ===
                      ("Transaction" || "MultiApprovalDeclinedTransaction")
                        ? BeneTxnDeclinedPopUp(accountId, declineReason)
                        : handleConfirm(accountId, declineReason)
                    }
                    disabled={inputDisabled || declineReason.trim().length <= 9}
                    style={{
                      cursor:
                        inputDisabled || declineReason.trim().length <= 9
                          ? "default"
                          : "pointer",
                    }}
                  >
                    Confirm
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : status === "Transaction-bene" ? (
          <div
            className="DeclinedAndpopup"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="declinedReasion">
              <h2>Rejected reason</h2>
              <p>Please state the reason for rejecting the transaction</p>
            </div>

            <div
              className={`Beneinputfield ${
                declineReason.length >= 9 ? "valid" : ""
              }`}
            >
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "1px solid #00FFF5"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                value={declineReason}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                for="myInput"
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>

            <div className="BeneTxn_benefbtnconfandCan">
              {showLoader ? (
                <button id="loader" className="BeneTxn_expentraLoaderclass">
                  <img src={xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <div className="flex items-center gap-[20px]">
                  {cancleButtonHide && (
                    <button
                      id="cancelBtn"
                      onClick={onClose}
                      className={`cancleBtn ${showTransitionClass}`}
                    >
                      Cancel
                    </button>
                  )}

                  <button
                    className={`confirmBtn ${showTransitionClass}`}
                    onClick={() =>
                      status === "Transaction-bene"
                        ? BeneTxnDeclinedDtls(accountId, declineReason)
                        : handleConfirm(accountId, declineReason)
                    }
                    disabled={inputDisabled || declineReason.trim().length <= 9}
                    style={{
                      cursor:
                        inputDisabled || declineReason.trim().length <= 9
                          ? "default"
                          : "pointer",
                    }}
                  >
                    Confirm
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : status === "Reject-All-Transaction" ? (
          <div
            className="DeclinedAndpopup"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="declinedReasion">
              <h2>Rejected reason</h2>
              <p>Please state the reason for rejecting the transaction</p>
            </div>

            <div
              className={`Beneinputfield ${
                declineReason.length >= 9 ? "valid" : ""
              }`}
            >
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "1px solid #00FFF5"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                value={declineReason}
                onChange={handleInputChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                for="myInput"
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>

            <div className="BeneTxn_benefbtnconfandCan">
              {showLoader ? (
                <button id="loader" className="BeneTxn_expentraLoaderclass">
                  <img src={xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <div className="flex items-center gap-[20px]">
                  {cancleButtonHide && (
                    <button
                      id="cancelBtn"
                      onClick={onClose}
                      className={`cancleBtn ${showTransitionClass}`}
                    >
                      Cancel
                    </button>
                  )}

                  <button
                    className={`confirmBtn ${showTransitionClass}`}
                    onClick={() => handleRejectAllTransaction()}
                    disabled={inputDisabled || declineReason.trim().length <= 9}
                    style={{
                      cursor:
                        inputDisabled || declineReason.trim().length <= 9
                          ? "default"
                          : "pointer",
                    }}
                  >
                    Confirm
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="DeclinedAndpopup">
            <div className="declinedReasion">
              <h2>Rejected reason </h2>
              <p>Please state the reason for rejecting the account</p>
            </div>
            <div className="Beneinputfield">
              <input
                style={{
                  border:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "1px solid #00FF14"
                      : isFocus
                      ? "1px solid #00FFF5"
                      : "1px solid #dadada",
                  pointerEvents: inputDisabled ? "none" : "auto",
                  disabled: inputDisabled ? "true" : "false",
                  width: "501px",
                  height: "42px",
                  font: "16px",
                  padding: "0px 10px",
                  background: "transparent",
                  borderRadius: "10px",
                  outline: "none",
                  opacity: "1",
                }}
                type="text"
                value={declineReason}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleInputChange}
                required
                disabled={inputDisabled}
              />
              <label
                htmlFor=""
                style={{
                  top: isFocus ? "-0px" : "22px",
                  fontSize: isFocus ? "12px" : "16px",
                  background: "#153164",
                  color:
                    declineReason.replace(/\s+/g, "").length >= 10
                      ? "#00FF14"
                      : isFocus
                      ? "#00FFF5"
                      : "#dadada",
                }}
              >
                Reason*
              </label>
            </div>
            <div className="benefbtnconfandCan">
              {cancleButtonHide && (
                <button
                  id="cancelBtn"
                  onClick={() => {
                    setRejectTxnPopup(false);
                    onClose();
                  }}
                  className={`cancleBtn ${showTransitionClass}`}
                >
                  Cancel
                </button>
              )}
              {showLoader ? (
                <button className="expentraLoaderclass">
                  <img src={xpentraloader} alt="Loading..." />
                </button>
              ) : (
                <button
                  className="confirmBtn"
                  onClick={() =>
                    status === "Transaction" || sideDrawer === "SideDrawer"
                      ? BeneTxnDeclinedPopUp(accountId, declineReason)
                      : handleRejectTransactionChecker(accountId, declineReason)
                  }
                  disabled={inputDisabled || declineReason.trim().length <= 9}
                  style={{
                    cursor:
                      inputDisabled || declineReason.trim().length <= 9
                        ? "default"
                        : "pointer",
                  }}
                >
                  Confirm
                </button>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeclinedPopup;
