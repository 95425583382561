import formatCurrency from "../../../../utils/CurrencyFormater";
import { Tooltip, tooltipClasses } from "@mui/material";
import BalanceLoader from "../../../_utils/BalanceLoader";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import refreshIcon from "../../../../assets/OwnerAccount/refresh.png";
import statementIcon from "../../../../assets/newdashboardicon/statememticon.png";
import { getOwnerAccBalance } from "../../../../redux/features/owner/ownerAllVerification/ownerAllVerification";
import { checkUsertoken } from "../../../../redux/features/login/login";
import "./index.css";
import { toast_position } from "../../../../redux/features/Settings/Authorization";
import { useState } from "react";
import AnimationUpAndDown from "../../../BeneTransaction/OwnerPopup/AnimationUpAndDown";
import FormatAmount from "../../../_utils/FormatAmount/FormatAmount";

import CardLogo from "../../../_utils/logo/CardLogo";
import IfscCodeMapper from "../../../_utils/IfscLogoMapper";
import { BorderTopRounded, Padding } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

function ConnectedBankOwnerCard({
  owner,
  ownerAccounts,
  setOwnerAccounts,
  ownerAccBalanceLoading,
  ownerAccBalanceError,
  updateTotalBalance,
  total_balance,
  connectedBankingOwnerAccounts,
  setConnectedBankingOwnerAccounts,
}) {
  const [accId, setAccId] = useState("");
  const [currentConnectedBal, setCurrentConnectedBal] = useState("");
  const [prevConnectedBal, setPrevConnectedBal] = useState("");
  const [refreshedConnectedId, setRefreshedConnectedId] = useState("");
  const [isMore, setIsMore] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bank_logo = IfscCodeMapper(owner.ifsc_code);

  const handleRefresh = async (id) => {
    try {
      dispatch(checkUsertoken());
      let response = await dispatch(getOwnerAccBalance(id));

      if (response?.payload?.status === true) {
        setRefreshedConnectedId(id);
        let updatedOwnersData = ownerAccounts?.map((item) => {
          if (
            response?.payload?.owner_accounts[0]?.owner_account_id === item?.id
          ) {
            return {
              ...item,
              owner_account_balance_last_fetched_at_in_words:
                response?.payload?.owner_accounts[0]
                  ?.owner_account_balance_last_fetched_at_in_words,
              owner_account_balance:
                response?.payload?.owner_accounts[0]?.owner_account_balance,
              owner_account_balance_last_fetched_at:
                response.payload?.owner_accounts[0]
                  ?.owner_account_balance_last_fetched_at,
            };
          }
          return item;
        });

        const updatedConnectedBankingData = connectedBankingOwnerAccounts?.map(
          (item) => {
            if (
              response?.payload?.owner_accounts[0]?.owner_account_id ===
              item?.id
            ) {
              return {
                ...item,
                owner_account_balance_last_fetched_at_in_words:
                  response?.payload?.owner_accounts[0]
                    ?.owner_account_balance_last_fetched_at_in_words,
                owner_account_balance:
                  response?.payload?.owner_accounts[0]?.owner_account_balance,
                owner_account_balance_last_fetched_at:
                  response.payload?.owner_accounts[0]
                    ?.owner_account_balance_last_fetched_at,
              };
            }
            return item;
          }
        );

        setOwnerAccounts(updatedOwnersData);
        setConnectedBankingOwnerAccounts(updatedConnectedBankingData);

        const prevBalance = connectedBankingOwnerAccounts?.find(
          (owner) => owner.id === id
        )?.owner_account_balance;

        setPrevConnectedBal(prevBalance);

        const refreshedBalance = response?.payload.owner_accounts.find(
          (owner) => owner.owner_account_id === id
        )?.owner_account_balance;

        setCurrentConnectedBal(refreshedBalance);

        const updatedConnectedBankingTotal =
          Number(total_balance) -
          Number(prevBalance) +
          Number(refreshedBalance);

        updateTotalBalance(String(updatedConnectedBankingTotal));

        if (refreshedBalance > prevBalance) {
          setIsMore(true);
        } else {
          setIsMore(false);
        }
      } else {
        setTimeout(() => {
          toast.dismiss();
          toast.error(response?.error.message, toast_position);
        }, 500);
      }
    } catch (error) {
      toast.error(error, toast_position);
      throw error;
    }
  };

  return (
    <>
      <div
        className="maindashboard-card"
        onClick={() => {
          navigate(`/accounts/owner/owner_account_details/${owner?.id}`);
        }}
      >
        <div className="maindashboard-card-left">
          <div className="maindashboard-card-leftg">
            <div className="bank-logom">
              <CardLogo
                bank={bank_logo}
                divCssProps={
                  "rounded-bl-3xl 5xl:rounded-bl-large drop-shadow-xl"
                }
              />
            </div>
            <div className="bank-account-no">
              <p>
                {" "}
                {owner.bank_acc_no.substring(0, 4) +
                  " •••• " +
                  owner.bank_acc_no.substring(owner.bank_acc_no.length - 4)}
              </p>
            </div>
          </div>
          <div className="maindashboard-card-leftgm">
            <div className="bank-account-name">
              <Tooltip
                componentsProps={{
                  popper: {
                    sx: {
                      [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: (theme) => theme.palette.common.black,
                      },
                    },
                  },
                }}
                title={owner.bank_account_holder_full_name}
              >
                {owner.bank_account_holder_full_name}
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="maindashboard-card-right">
          <AnimationUpAndDown
            loader={ownerAccBalanceLoading}
            currentId={refreshedConnectedId}
            previousId={owner?.id}
            currentBalance={currentConnectedBal}
            previousBalance={prevConnectedBal}
            refreshType={"single"}
            classType={"owner_connected"}
          />
          <div className="right-first-child">
            <p>
              ₹{" "}
              <FormatAmount
                price={
                  owner.owner_account_balance === "0"
                    ? "0.0"
                    : owner.owner_account_balance === "0.0"
                    ? "0.0"
                    : (
                        Math.floor(Number(owner.owner_account_balance) * 100) /
                        100
                      ).toFixed(2)
                }
              />
            </p>
            <p>
              Updated - {owner?.owner_account_balance_last_fetched_at_in_words}
            </p>
          </div>
          <Tooltip
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                  },
                },
              },
            }}
            title="Refresh"
          >
            <div
              className="right-second-childu "
              onClick={() => {
                handleRefresh(owner?.id);
                setAccId(owner?.id);
              }}
            >
              {ownerAccBalanceLoading && accId === owner?.id ? (
                <BalanceLoader loader={ownerAccBalanceLoading} />
              ) : !ownerAccBalanceLoading &&
                !ownerAccBalanceError &&
                accId === owner?.id ? (
                <BalanceLoader
                  loader={ownerAccBalanceLoading}
                  setAccId={setAccId}
                />
              ) : (
                <img src={refreshIcon} alt="refresh icon" />
              )}
            </div>
          </Tooltip>
          <div className="right-first-child"></div>
          <Tooltip
            componentsProps={{
              popper: {
                sx: {
                  [`& .${tooltipClasses.tooltip}`]: {
                    backgroundColor: (theme) => theme.palette.common.black,
                  },
                },
              },
            }}
            title="Statement"
          >
            <div className="right-second-childd ">
              <img
                src={statementIcon}
                alt="statement icon"
                style={{ width: "50%" }}
              />
            </div>
          </Tooltip>
        </div>
        <br className="clearfix" />
      </div>
    </>
  );
}

export default ConnectedBankOwnerCard;
