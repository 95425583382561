import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Link, useNavigate } from "react-router-dom";
import MenuItemCardmobile from "../../MenuItemCardmobile";
import checkIfEnable from "../../../../utils/checkIfEnable";
import ServiceIDByLabel from "../../../../services/ServiceIDByLabel";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { useRef } from "react";
import "./index.css";
import { setCurrentMenu } from "../../../../redux/features/hideBreadCrumb/hideBreadCrumb";


export default function MobileViewPopover(props) {
  const services_list = useSelector((state) => state.refreshToken.services);
  const elementRef = useRef(null);
  const setOpenMobilePopover = props.setOpenMobilePopover;
  const setArrowUpm = props.setArrowUp;
  const dispatch = useDispatch();

  const handleTogglePopover = () => {
    setOpenMobilePopover(false);
    setArrowUpm(false);
  };
   const hidePopover = () => {
     setOpenMobilePopover(false);
  };
  const [state, setState] = useState({
    bottom: true,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setOpenMobilePopover(open);
  };

  return (
    <div>
      {["bottom"].map((anchor) => (
        <div ref={elementRef} className="popover-main-mobile">
          <Box
            sx={{
              width: "95%",
              boxShadow: "none",
              margin: "auto",
              flexGrow: 1,
              height: "120px",
              cursor: "pointer"
            }}
          >
            {props.data.map((transaction, index) =>
              transaction.title !== "Admin Transactions" ? (
                <Box>
                {transaction.menuItems.map((menuItem, index) => (
                  <Link
                    to={`/${menuItem.route}`}
                    style={{
                      textDecoration: "none",
                      pointerEvents: checkIfEnable(
                        ServiceIDByLabel,
                        menuItem.label,
                        services_list
                      )
                        ? "auto"
                        : "none",
                    }}
                    onClick={() => {
                      dispatch(setCurrentMenu(menuItem.menu));
                      handleTogglePopover();
                    }}
                  >
                    {" "}
                    <MenuItemCardmobile
                      key={index}
                      icon={menuItem.icon}
                      labelm={menuItem.labelm}
                      labela={menuItem.labela}
                    />
                  </Link>
                ))}
                <div key={index} className="borderright" style={{ height: "100px", float: "left",margin:"20px 0px",display: index % 2 === 1 ? 'none' : 'block',}}></div>
                </Box>
              ) : null
              )}               
          </Box>
        </div>
      ))}
      <div className="overlay" onClick={() => {
        hidePopover();
      }}></div>
    </div>
  );
}
